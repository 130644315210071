import React, { useState } from "react";
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDownIcon, ChevronUpIcon, TrashIcon } from '@heroicons/react/24/outline'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import TextInput from "../Inputs/TextInput";
import DurationInput from "../Inputs/DurationInput";
import PrioritySelector from "../Dropdowns/PrioritySelector";
import ProgressSelector from "../Dropdowns/ProgressSelector";
import Pill from "../Badges/Pill";
import Badge from "../Badges/Badge";
import '../../utils/one-off-styles/antd.css'

const progressToColor = {
    'Done': 'green',
    'In Progress': 'yellow',
    'Behind': 'red',
    'Not Started': 'gray'
}

const priorityToColor = {
    'Low': 'green',
    'Medium': 'yellow',
    'High': 'orange',
    'Urgent': 'red',
    'None': 'gray'
}

const StandaloneTaskConfigureContainer = ({
    task,
    setTask,
    deleteTask }) => {
    const [expanded, setExpanded] = useState(false)
    const { RangePicker } = DatePicker;

    return (
        <div
            className="mt-2 bg-white cursor-pointer w-full p-3 rounded-lg border-2 flex flex-row items-center border-gray-100 shadow-lg w-full"
            onClick={() => { !expanded && setExpanded(true) }}
        >
            {!expanded ?
                <>
                    <div className="w-full">
                        <div className="flex items-center w-fit justify-between mb-2">
                            <p className="font-medium text-sm mr-3">{task.name}</p>
                            <div className="space-x-3">
                                <Pill variant={priorityToColor[task.priority]}>{task.priority}</Pill>
                                <Badge variant={progressToColor[task.status]}>{task.status}</Badge>
                            </div>
                        </div>
                        <div className="mt-1">
                            <p className="text-sm text-gray-600">{`Due on ${dayjs(task.deadline).format("MMM D YYYY, h:mm a")} • Time needed ${task.timeNeeded}`}</p>
                        </div>
                    </div>
                    <TrashIcon
                        className='h-5 w-5 mr-3 text-gray-500 cursor-pointer hover:text-red-500'
                        onClick={() => deleteTask(task.id)}
                    />
                    <ChevronDownIcon
                        className='h-5 w-5 text-gray-500 cursor-pointer'
                        onClick={() => setExpanded(true)}
                    />
                </>
                :
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        className="w-full flex flex-row items-center"
                    >
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <div className='w-4/5'>
                                    {/* <label htmlFor="priority" className="text-sm font-medium text-gray-900">Project name</label> */}
                                    <TextInput
                                        id='project-name'
                                        type='text'
                                        placeholder='Write a task name'
                                        value={task.name}
                                        label='Task Name'
                                        onChange={e => setTask(task.id, { ...task, name: e.target.value })}
                                        className='mb-4'
                                    />
                                </div>
                                <div className="flex items-center space-x-2 pb-3">
                                    <div className='flex flex-col'>
                                        <label htmlFor="priority" className="ml-5 text-sm font-medium text-gray-900">Priority</label>
                                        <PrioritySelector
                                            priority={task.priority}
                                            setPriority={value =>
                                                setTask(task.id, { ...task, priority: value })}
                                        />
                                    </div>
                                    <div className='flex flex-col'>
                                        <label htmlFor="priority" className="ml-5 text-sm font-medium text-gray-900">Status</label>
                                        <ProgressSelector
                                            progress={task.status}
                                            className={"!w-28"}
                                            setProgress={value => setTask(task.id, { ...task, status: value })}
                                        />

                                    </div>
                                </div>

                            </div>
                            <div className='flex items-center justify-start'>
                                <div className='flex flex-col w-1/2'>
                                    <label htmlFor="start-date" className="text-sm font-medium text-gray-900 mb-2">Duration</label>
                                    <RangePicker
                                        id='duration'
                                        use12Hours
                                        changeOnBlur
                                        minuteStep={5}
                                        allowClear={false}
                                        popupClassName='noFooterTimePick'
                                        showTime={{
                                            format: 'h:mm a',
                                        }}
                                        value={
                                            [
                                                dayjs(task.start).isValid() ? dayjs(task.start) : undefined,
                                                dayjs(task.deadline).isValid() ? dayjs(task.deadline) : undefined
                                            ]
                                        }
                                        format="MM/DD/YYYY h:mm a"
                                        onChange={value => {
                                            setTask(task.id, { ...task, start: value[0], deadline: value[1] });
                                        }}
                                    />
                                    {task.start && task.deadline && dayjs(task.start).isSame(dayjs(task.deadline), 'minute') && <p className='text-xs font-medium mt-1 ml-1 text-red-500'>Start date must be before due date</p>}

                                </div>
                                <div className='ml-5 flex flex-col w-1/5'>
                                    <label htmlFor="time-needed" className="text-sm font-medium text-gray-900">Time Needed</label>
                                    <DurationInput
                                        className='w-36 h-8'
                                        placeholder='1 hr 30 min'
                                        value={task.timeNeeded}
                                        onChange={e => setTask(task.id, { ...task, timeNeeded: e })}
                                    />
                                    {/* This is for sizing */}
                                    {task.start && task.deadline && dayjs(task.start).isSame(dayjs(task.deadline), 'minute') && <p className='text-xs invisible font-medium mt-1 ml-1 text-red-500'>Start date must be before due date</p>}

                                </div>


                            </div>
                        </div>
                        <ChevronUpIcon
                            className='h-5 w-5 text-gray-500 cursor-pointer'
                            onClick={() => setExpanded(false)}
                        />
                    </motion.div>
                </AnimatePresence>
            }

        </div>
    )
}


export default StandaloneTaskConfigureContainer