import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Badge from '../Badges/Badge';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ProgressSelector = ({ progress, setProgress, className }) => {

    const progressToColor = {
        'Done': 'green',
        'In Progress': 'yellow',
        'Behind': 'red',
        'Not Started': 'gray'
    }

    return (
        <Menu as="div" className={`relative ml-2 mt-1 w-24 inline-block text-center ${className}`}>
            <div>
                <Menu.Button className="inline-flex  justify-center gap-x-1.5 rounded-md bg-white py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                    <Badge variant={progressToColor[progress]}>{progress}</Badge>
                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2  px-2 origin-center rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => setProgress('Done')}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block w-full  py-2 text-left text-sm text-left'
                                    )}
                                >
                                    <Badge variant='green'>Done</Badge>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => setProgress('In Progress')}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block w-full  py-2 text-left text-sm text-left'
                                    )}
                                >
                                    <Badge variant='yellow'>In Progress</Badge>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => setProgress('Behind')}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block w-full  py-2 text-left text-sm text-left'
                                    )}
                                >
                                    <Badge variant='red'>Behind</Badge>
                                </button>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <button
                                    onClick={() => setProgress('Not Started')}
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block w-full  py-2 text-left text-sm text-left'
                                    )}
                                >
                                    <Badge variant='gray'>Not Started</Badge>
                                </button>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default ProgressSelector