import React, { useState } from 'react';
import { motion, useSpring } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import usePost from '../hooks/usePost';
import logo from '../images/Logo.png';
import SideImage from '../images/Simple-bg2.png';
import Button from '../components/Buttons/Button';
import SplitWithImageRight from '../layouts/SplitWithImageRight';

const WorkPreferences = () => {
    const [preferences, setPreferences] = useState('');
    const [setPreferencesResponse, , setPreferencesIsLoading, setPreferencesError] = usePost('/user/update-preferences');
    const scaleX = useSpring(0.85, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    const navigate = useNavigate();

    const handleSave = async () => {
        try {
            const response = await setPreferencesResponse({
                preferences: preferences,
                orgId: Cookies.get('org')
            });
            navigate('/?onboarding=true');
        } catch (error) {
            console.error(error);
        }
    }

    const handleSkip = () => {
        navigate('/?onboarding=true');
    }

    const content = (
        <motion.div className='flex flex-col justify-between' initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <div className='mb-5'>
                <img src={logo} alt='Apollo Logo' className='mx-auto h-12 w-auto mb-5' />
                <h1 className='text-center text-4xl font-extrabold text-gray-900 mb-5'>
                    Set your preferences
                </h1>
                <p className='text-left text-sm text-gray-600 mb-3'>
                    Specify any preferences you have for your work schedule. Apollo will use these to optimize your calendar. You can always change this later.
                </p>
                <div className='space-y-3'>
                    <textarea
                        rows={5}
                        name="preferences"
                        id="preferences"
                        className="block w-full bg-offwhite resize-none border-0 border-b border-transparent p-1 pb-2 text-gray-900 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Schedule more intense work in the afternoons, no meetings after 3pm, etc..."
                        value={preferences}
                        onChange={(e) => setPreferences(e.target.value)} />

                    <div className="flex justify-between space-x-4 mt-4">
                        <Button variant="neutral" onClick={handleSkip}>Skip</Button>
                        <Button variant="additive" onClick={handleSave} loading={setPreferencesIsLoading}>Save</Button>
                    </div>
                </div>
            </div>

        </motion.div>
    )

    return (
        <>
            <motion.div className='progress-bar' style={{ scaleX }} />

            <SplitWithImageRight
                image={SideImage}
                content={content}
            /></>
    );
};

export default WorkPreferences;
