const convertPriorityToNumber = (priority) => {
    try {
        switch(priority) {
            case 'None':
                return 1;
            case 'Low':
                return 2;
            case 'Medium':
                return 3;
            case 'High':
                return 4;
            case 'Urgent':
                return 5;
            default:
                return 0;
        }
    } catch (error) {
        console.error(error)
        return 0
    }
}

export default convertPriorityToNumber;