import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { motion } from 'framer-motion';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const DropdownButton = ({ children, variant = 'neutral', items, onClick, disabled, loading }) => {
    const buttonBaseStyle =
        `relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-medium  ring-1 ring-inset focus:z-10`

    const menuButtonBaseStyle =
        `relative inline-flex items-center rounded-r-md px-2 py-2 ring-1 ring-inset focus:z-10`


    const variantStyles = {
        additive:
            'bg-main-purple ring-offwhite text-white hover:bg-purple-800 ',
        destructive: 'bg-red-500 text-white ring-red-700 hover:bg-red-600 ',
        neutral: 'bg-gray-300 text-gray-800 hover:bg-gray-400 '
    };

    const variantScales = {
        additive: { hover: 1.05, tap: 0.9 },
        destructive: { hover: 1.05, tap: 0.9 },
        neutral: { hover: 1.02, tap: 0.98 }
    };
    return (
        <motion.div
            whileHover={{ scale: disabled ? 1.0 : variantScales[variant].hover }}
            whileTap={{ scale: disabled ? 1.0 : variantScales[variant].tap }}
            // onClick={disabled || loading ? null : onClick}
            className="inline-flex rounded-md shadow-sm"
        >
            <button
                type="button"
                onClick={onClick}
                className={`${variantStyles[variant]} ${buttonBaseStyle}`}
            >
                {children}
            </button>
            <Menu as="div" className="relative -ml-px block">
                <Menu.Button className={`${variantStyles[variant]} ${menuButtonBaseStyle}`}>
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className={`bg-white absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                        <div className="py-1">
                            {items.map((item) => (
                                <Menu.Item key={item.name}>
                                    {({ active }) => (
                                        <a
                                            onClick={item.onClick}
                                            className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm cursor-pointer'
                                            )}
                                        >
                                            {item.name}
                                        </a>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </motion.div>
    )
}

export default DropdownButton