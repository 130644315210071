import React, { useMemo, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import convertDurationToSeconds from '../../../utils/formatters/ConvertDuration'
import convertPriorityToNumber from '../../../utils/formatters/ConvertPriority'
import usePost from '../../../hooks/usePost'
import useFetch from '../../../hooks/useFetch'
import Button from '../../Buttons/Button'
import CircleButton from '../../Buttons/CircleButton'
import getRandomColor from '../../../utils/colors/RandomColor'
import ProjectConfigureContainer from '../../Containers/ProjectConfigureContainer'
import StandaloneTaskConfigureContainer from '../../Containers/StandaloneTaskConfigureContainer'

const formatTeam = (teamMembers) => {
    try {
        return teamMembers?.users.map(member => ({
            name: member.name,
            userId: member.userId
        }))
    } catch (error) {
        console.error(error)
        return []
    }
}

const ConfirmBrainDump = ({
    actionItems,
    setActionItems,
    refresh,
    refreshTodos,
    setShowNotification,
    setNotificationBody,
    setIsVisible,
    waitingText,
    clear }) => {
    const [createProjectId, , projectIdIsLoading, projectIdError] = usePost('/project/create')
    const [createTask, , createTaskIsLoading, createTaskError] = usePost('/task/schedule')
    const additionalParams = useMemo(() => ({}), []);
    const { data: teamMembers, loading, error, refresh: refreshTeam } = useFetch('/org/list-users', additionalParams)


    const addProject = () => {
        const updatedProjects = [...actionItems.projects, {
            id: uuidv4(),
            name: '',
            status: 'Not Started',
            priority: "None",
            timeNeeded: '',
            start: dayjs(),
            deadline: dayjs().add(1, 'day'),
            description: '',
            tasks: []
        }]
        setActionItems({ ...actionItems, projects: updatedProjects })
    }

    const updateProject = (projectId, updatedFields) => {
        const updatedProjects = actionItems.projects.map(project =>
            project.id === projectId ? { ...project, ...updatedFields } : project
        );
        setActionItems({ ...actionItems, projects: updatedProjects });
    }

    const deleteProject = (projectId) => {
        const updatedProjects = actionItems.projects.filter(project => project.id !== projectId);
        setActionItems({ ...actionItems, projects: updatedProjects });
    }

    const addTask = () => {
        const updatedTasks = [...actionItems.tasks, {
            id: uuidv4(),
            name: '',
            priority: 'None',
            status: 'Not Started',
            start: dayjs(),
            deadline: dayjs().add(1, 'day'),
            timeNeeded: '',
            split: true
        }]
        setActionItems({ ...actionItems, tasks: updatedTasks })
    }


    const updateTask = (taskId, updatedFields) => {
        const updatedTasks = actionItems.tasks.map(task =>
            task.id === taskId ? { ...task, ...updatedFields } : task
        );
        setActionItems({ ...actionItems, tasks: updatedTasks });
    }

    const deleteTask = (taskId) => {
        const updatedTasks = actionItems.tasks.filter(task => task.id !== taskId);
        setActionItems({ ...actionItems, tasks: updatedTasks });
    }

    const isDisabled = () => {
        try {
            const requiredFields = ['start', 'deadline', 'name', 'timeNeeded'];

            for (let project of actionItems.projects) {
                for (let field of requiredFields) {
                    if (!project[field] || project[field] === '') return true;
                }

                // Check tasks within the project
                for (let task of project.tasks) {
                    for (let field of requiredFields) {
                        if (!task[field] || task[field] === '') return true;
                    }
                }
            }

            for (let task of actionItems.tasks) {
                for (let field of requiredFields) {
                    if (!task[field] || task[field] === '') return true;
                }
            }

            return false;
        } catch (error) {
            return true;
        }
    }

    const handleScheduleAll = async () => {
        try {
            // Schedule project first
            // Loop through all projects
            for (const project of actionItems.projects) {
                const projectColor = getRandomColor();
                let projectStart = typeof project.start === 'object' ? project.start : dayjs(project.start);
                let projectEnd = typeof project.deadline === 'object' ? project.deadline : dayjs(project.deadline);

                // Swap if begin is greater than end
                if (projectStart.isAfter(projectEnd)) {
                    [projectStart, projectEnd] = [projectEnd, projectStart];
                }

                const projectIdResponse = await createProjectId({
                    project: {
                        name: project.name,
                        begin: projectStart.format('YYYY-MM-DDTHH:mm:ssZ'),
                        end: projectEnd.format('YYYY-MM-DDTHH:mm:ssZ'),
                        timeNeeded: convertDurationToSeconds(project.timeNeeded),
                        priority: convertPriorityToNumber(project.priority),
                        status: project.status,
                        color: projectColor,
                        description: project.description,
                        orgId: Cookies.get("org")
                    }
                })

                const projectId = projectIdResponse.data.project.projectId

                if (project.tasks.length === 0) {
                    // create a task for the entire object
                    const taskRequestBody = {
                        task: {
                            "name": project.name,
                            "color": projectColor,
                            "begin": typeof project.start === 'object' ? project.start.format('YYYY-MM-DDTHH:mm:ssZ') : project.start,
                            "end": typeof project.deadline === 'object' ? project.deadline.format('YYYY-MM-DDTHH:mm:ssZ') : project.deadline,
                            "description": project.description,
                            "orgId": Cookies.get("org"),
                            "status": project.status,
                            "timeSpend": convertDurationToSeconds(project.timeNeeded),
                            "priority": convertPriorityToNumber(project.priority),
                            "projectId": projectId,
                            "orgId": Cookies.get("org"),
                        },
                        
                    };
                    // If timeSpend is more than 4 hours, set timeblock min to 1 hr and max to 4 hrs
                    if (taskRequestBody.task.timeSpend > 14400) {
                        taskRequestBody.task.timeblockMin = 3600
                        taskRequestBody.task.timeblockMax = 14400
                    }
                    const taskResponse = await createTask(taskRequestBody);
                } else {
                    let previousTaskEnd = dayjs();

                    for (const task of project.tasks) {
                        let taskEnd = typeof task.deadline === 'object' ? task.deadline : dayjs(task.deadline);

                        const taskRequestBody = {
                            task: {
                                "name": task.name,
                                "color": projectColor,
                                "begin": previousTaskEnd.format('YYYY-MM-DDTHH:mm:ssZ'),
                                "end": taskEnd.format('YYYY-MM-DDTHH:mm:ssZ'),
                                "description": "",
                                "orgId": Cookies.get("org"),
                                "status": task.status,
                                "timeSpend": convertDurationToSeconds(task.timeNeeded),
                                "priority": convertPriorityToNumber(task.priority),
                                "projectId": projectId,
                                "orgId": Cookies.get("org")
                            },
                            
                        }
                        // If timeSpend is more than 4 hours, set timeblock min to 1 hr and max to 4 hrs
                        if (taskRequestBody.task.timeSpend > 14400) {
                            taskRequestBody.task.timeblockMin = 3600
                            taskRequestBody.task.timeblockMax = 14400
                        }

                        if (task.assignee && task.assignee.userId !== -1) {
                            taskRequestBody.userId = task.assignee.userId;
                        }

                        const response = await createTask(taskRequestBody)
                        previousTaskEnd = taskEnd;
                    }
                }

            }

            // Schedule tasks
            // Loop through all tasks
            for (const task of actionItems.tasks) {
                let taskStart = typeof task.start === 'object' ? task.start : dayjs(task.start);
                let taskEnd = typeof task.deadline === 'object' ? task.deadline : dayjs(task.deadline);

                // Swap if begin is greater than end
                if (taskStart.isAfter(taskEnd)) {
                    [taskStart, taskEnd] = [taskEnd, taskStart];
                }

                const requestBody = {
                    task: {
                        "name": task.name,
                        "color": "purple",
                        "status": "Not Started",
                        "priority": convertPriorityToNumber(task.priority),
                        "begin": taskStart.format('YYYY-MM-DDTHH:mm:ssZ'),
                        "end": taskEnd.format('YYYY-MM-DDTHH:mm:ssZ'),
                        "description": "",
                        "timeSpend": convertDurationToSeconds(task.timeNeeded),
                        "orgId": Cookies.get("org")
                    },
                   
                }
                // If timeSpend is more than 4 hours, set timeblock min to 1 hr and max to 4 hrs
                if (requestBody.task.timeSpend > 14400) {
                    requestBody.task.timeblockMin = 3600
                    requestBody.task.timeblockMax = 14400
                }
                const response = await createTask(requestBody)
            }
            setNotificationBody({
                title: "Items created successfully 🎉",
                positive: true,
            })
            setShowNotification(true)
            refresh()
            refreshTodos()
            setIsVisible(false)
            clear()
        } catch (error) {
            console.error(error)
            setNotificationBody({
                title: "Error creating items",
                subtext: "Please try again",
                positive: false,
            })
            setShowNotification(true)
        }
    }

    return (
        <div className="flex flex-col bg-white" >
            <h2 className="text-xl font-bold mb-1">Action items</h2>
            <p className='text-sm text-gray-600' style={{ whiteSpace: 'pre-line' }}>{waitingText}</p>
            <div className='max-h-96 overflow-y-auto p-2'>
                {actionItems?.projects?.length > 0 && (
                    <div className="flex w-1/12 items-center mb-1">
                        <label className="text-base font-bold text-gray-900 mt-1 mb-1 mr-2">Projects</label>
                        <CircleButton className="my-auto" onClick={() => addProject()}>+</CircleButton>
                    </div>
                )}
                {actionItems?.projects?.map((project) => (
                    <ProjectConfigureContainer
                        project={project}
                        setProject={updateProject}
                        teamMembers={formatTeam(teamMembers)}
                        deleteProject={deleteProject}

                    />
                ))}
                {actionItems?.tasks?.length > 0 &&
                    (
                        <div className="flex w-1/2 items-center mb-1 mt-2">
                            <label className="text-base font-bold text-gray-900 mt-1 mb-1 mr-2">Standalone Tasks</label>
                            <CircleButton className="my-auto" onClick={() => addTask()}>+</CircleButton>
                        </div>
                    )}
                {actionItems?.tasks?.map((task) => (
                    <StandaloneTaskConfigureContainer
                        task={task}
                        setTask={updateTask}
                        deleteTask={deleteTask}
                    />
                ))}
            </div>
            <div className="mt-3 flex justify-end">
                <div className="flex w-1/5">
                    <Button
                        variant="additive"
                        disabled={isDisabled()}
                        onClick={handleScheduleAll}
                        loading={projectIdIsLoading || createTaskIsLoading}
                        className="w-1/4"
                    >
                        Schedule All ✨
                    </Button>
                </div>
            </div>
        </div >
    )
}

export default ConfirmBrainDump