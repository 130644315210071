import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const ColorSelector = ({ color, setColor }) => {
    const colorToClass = {
        'purple': 'bg-event-bg-purple',
        'green': 'bg-event-bg-green',
        'yellow': 'bg-event-bg-yellow',
        'blue': 'bg-event-bg-blue',
        'red': 'bg-event-bg-red',
    }

    return (
        <Menu as="div" className="relative inline-block text-center">
            <div>
                <Menu.Button className="inline-flex p-2.5  items-center justify-center rounded-md bg-white text-sm font-semibold text-gray-900 hover:bg-gray-50">
                    <div className={`h-7 w-7 rounded-full mt-2.5 ${colorToClass[color]}`}>
                    </div>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute right-0 z-10 mt-2  px-2 origin-center rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {Object.keys(colorToClass).map((colorKey) => (
                            <Menu.Item key={colorKey}>
                                {({ active }) => (
                                    <button
                                        onClick={() => setColor(colorKey)}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block w-full  p-2 text-left text-sm text-left'
                                        )}
                                    >
                                        <div className={`h-7 w-7 rounded-full mt-2.5 ${colorToClass[colorKey]}`}>
                                        </div>
                                    </button>
                                )}
                            </Menu.Item>
                        ))}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default ColorSelector