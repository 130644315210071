import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { FlagIcon } from '@heroicons/react/20/solid'

const colorByDueDate = {
    0: 'bg-red-500 text-white',
    1: 'bg-red-500 text-white',
    2: 'bg-yellow-500 text-white',
    3: 'bg-green-500 text-white'
}

const flagColorByPriority = {
    'Low': 'text-green-500',
    'Medium': 'text-yellow-500',
    'High': 'text-orange-500',
    'Urgent': 'text-red-500'
}

const colorForDue = (dueDate) => {
    const color = colorByDueDate[dueDate]
    return color ? color : 'bg-green-500 text-white'
}


const TodoContainer = ({ todoDay, completeTodo }) => {
    const [expandedTodo, setExpandedTodo] = useState(null);
    return (
        <motion.div initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }} className='p-4 bg-white rounded-lg shadow-lg border border-gray-200'>
            <h2 className='text-md text-gray-600 font-semibold mb-2'>{todoDay.day}</h2>
            {todoDay.todos.map((todo, index) => (
                <div key={index} className=''>
                    <div className='flex items-center cursor-pointer py-2 justify-between' onClick={() => setExpandedTodo(index === expandedTodo ? null : index)}>
                        <div className='flex items-center'>
                            <input
                                id="todo"
                                type="checkbox"
                                defaultChecked={todo.completed}
                                onChange={() => completeTodo(todo.stableId)}

                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <p className='ml-3 text-sm font-semibold'>{todo.name}</p>
                        </div>
                        <ChevronDownIcon
                            className='h-5 w-5 text-gray-500 cursor-pointer'
                            onClick={() => setExpandedTodo(index === expandedTodo ? null : index)}
                        />
                    </div>
                    <AnimatePresence>
                        {index === expandedTodo && (
                            <motion.div
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: "auto" }}
                                exit={{ opacity: 0, height: 0 }}
                                className='mt-1 pl-6'>
                                <p className='text-xs font-semibold text-gray-500'>{todo.startTime} <span aria-hidden="true">&rarr;</span> {todo.endTime}</p>
                                <div className='flex items-center mt-1'>
                                    <FlagIcon className={`mt-1 h-4 w-4 ${flagColorByPriority[todo.priority]}`} />
                                    <p className='ml-1 text-xs font-semibold text-gray-500'>{todo.priority} Priority</p>
                                </div>
                                <div className='mt-1 w-1/2'>
                                    <p className={`text-xs rounded-full text-center py-0.5 ${colorForDue(todo.daysUntilDue)}`}>{`Due in ${todo.daysUntilDue} day${todo.daysUntilDue > 1 ? 's' : ''}`}</p>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    {index !== todoDay.todos.length - 1 && (<hr className='my-2 w-full border-t-1 border-gray-300' />)}

                </div>
            ))}
        </motion.div>
    );
};

export default TodoContainer;
