import React from "react"
import dayjs from "dayjs";
import { DatePicker } from 'antd'
import { XMarkIcon } from "@heroicons/react/24/outline";
import TextInput from "../Inputs/TextInput";
import DurationInput from "../Inputs/DurationInput";

const ApproveProjectTable = ({ tasks, editTask, deleteTask }) => {
    const { RangePicker } = DatePicker;
    return (
        <div className="px-2 flow-root">
            <div className="flow-root mb-5 overflow-visible">
                <div className="inline-block min-w-full py-2 align-middle">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="border-t divide-x divide-gray-200">
                            <tr className="divide-x divide-gray-200">
                                <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    Name
                                </th>
                                <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Time needed
                                </th>
                                <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Duration
                                </th>

                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                    <span className="sr-only">Remove</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {tasks?.map((task) => (
                                <tr key={task?.id} className="divide-x divide-gray-200">
                                    <td className="whitespace-nowrap text-left text-sm font-normal text-gray-900 pr-3 ">
                                        <TextInput
                                            id='task-name'
                                            type='text'
                                            placeholder='Write a task name'
                                            value={task?.name}
                                            onChange={e => editTask(task?.id, { ...task, name: e.target.value })}
                                            className='cursor-pointer focus:!cursor-auto !ring-0 !shadow-none focus:!border-b-1 focus:!border-indigo-500 focus:!ring-1 mt-0'
                                        />
                                    </td>
                                    <td className="w-44 whitespace-nowrap text-left pl-2 text-sm text-gray-500">
                                        <DurationInput
                                            className='w-full h-7 cursor-pointer focus:!cursor-auto !ring-0 !shadow-none focus:!border-b-1 focus:!border-indigo-500 focus:!ring-1 mt-0'
                                            placeholder='1 hr 30 min'
                                            value={task?.timeNeeded}
                                            onChange={e => editTask(task?.id, { ...task, timeNeeded: e })}
                                        />
                                    </td>
                                    <td className="w-96 whitespace-nowrap pl-2 pr-4 text-sm text-gray-500 sm:pr-0 text-left">
                                        <RangePicker
                                            id='duration'
                                            use12Hours
                                            changeOnBlur
                                            minuteStep={5}
                                            allowClear={false}
                                            popupClassName='noFooterTimePick'
                                            showTime={{
                                                format: 'h:mm a',
                                            }}
                                            value={
                                                [
                                                    dayjs(task?.start).isValid() ? dayjs(task?.start) : undefined,
                                                    dayjs(task?.deadline).isValid() ? dayjs(task?.deadline) : undefined
                                                ]
                                            }
                                            format="MM/DD/YYYY h:mm a"
                                            onChange={value => {
                                                editTask(task?.id, { ...task, start: value[0], deadline: value[1] });
                                            }}
                                        />
                                    </td>

                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-6 text-left text-sm font-medium sm:pr-0">
                                        <XMarkIcon className="h-4 w-4 text-gray-400 hover:text-red-400 cursor-pointer"
                                            onClick={() => deleteTask(task?.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ApproveProjectTable;
