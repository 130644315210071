import React, { useState, useRef, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import { Dialog } from '@headlessui/react'
import useFetch from '../../../hooks/useFetch';
import usePost from '../../../hooks/usePost';
import Spinner from '../../LoadingWheels/Spinner';
import Logo from "../../../images/Logo.png"
import Modal from '../Modals/Modal';
import convertNumberToPriority from '../../../utils/formatters/NumberToPriority';
import secondsToDuration from '../../../utils/formatters/SecondsToDuration';
import CommandBarTask from './CommandBarTask';

const formatProjects = (projectsData) => {
    try {
        return projectsData.projects.map(_project => {
            const project = _project.project
            return {
                id: project.projectId,
                creatorId: project.creatorId,
                creatorName: projectsData.users.find(member => member.userId === project.creatorId).name,
                name: project.name,
                status: project.status,
                priority: convertNumberToPriority(project.priority),
                timeNeeded: secondsToDuration(project.timeNeeded),
                description: project.description,
                start: project.begin,
                deadline: project.end,
                color: project.color,
                tasks: _project.tasks.map(task => {
                    const assignee = projectsData.users.find(member => member.userId === task.userId);
                    return {
                        id: task.taskId,
                        name: task.name,
                        timeNeeded: secondsToDuration(Number(task.timeSpend)),
                        timeblockMin: Number(task.timeblockMin),
                        timeblockMax: Number(task.timeblockMax),
                        start: task.begin,
                        deadline: task.end,
                        priority: convertNumberToPriority(task.priority),
                        status: task.status,
                        orgId: task.orgId,
                        assignee: assignee ? { userId: assignee.userId, name: assignee.name } : undefined,
                        color: project.color,
                    };
                })
            }
        })
    } catch (error) {
        console.error(error);
        return []
    }
};

const formatTodos = (todos) => {
    let formattedTodos = [];
    for (let date in todos) {
        if (todos[date].length > 0) {
            let apolloEvents = todos[date].filter(event => event.originalSource === 'apollo');
            if (apolloEvents.length > 0) {
                formattedTodos.push({
                    day: dayjs(date).format("ddd, MMM D"),
                    todos: apolloEvents.map(event => ({
                        stableId: event.stableId,
                        name: event.name,
                        startTime: dayjs(event.begin).format("h:mm a"),
                        endTime: dayjs(event.end).format("h:mm a"),
                        daysUntilDue: event.daysLeft,
                        completed: event.complete,
                        priority: convertNumberToPriority(event.priority),
                    }))
                });
            }
        }
    }
    return formattedTodos;
}

const CommandBar = ({ isVisible, setIsVisible, refresh }) => {
    const additionalEmptyParams = useMemo(() => ({}), []);
    const additionalParams = useMemo(() => ({ begin: dayjs().format('YYYY-MM-DD'), end: dayjs().add(1, 'week').format('YYYY-MM-DD'), tzinfo: dayjs.tz.guess() }), []);

    const { data: projectsData, loading: projectsLoading, error: projectsError, refresh: projectsRefresh } = useFetch('/org/list-projects', additionalEmptyParams)
    const { data: userData, isLoading: userDataIsLoading, error: userDataError, refresh: refreshUser } = useFetch('/user/get', additionalEmptyParams)
    const { data: todos, loading: todosLoading, error: todosError, refresh: todosRefresh } = useFetch('/user/todo-list', additionalParams)

    const [startStream, , startStreamIsLoading, startStreamError] = usePost('/beta/chat-start', null, process.env.REACT_APP_RENDER_BACKEND_URL);
    const [parsingJson, setParsingJson] = useState(false)
    const [messages, setMessages] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [context, setContext] = useState({})

    const chatEndRef = useRef(null);

    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        if (projectsData) {
            const formattedProjects = formatProjects(projectsData);
            setContext({ ...context, projects: formattedProjects })
        }
    }, [projectsData]);

    useEffect(() => {
        if (userData) {
            const tzInfo = userData.user.workingHours.tzinfo;
            setContext({ ...context, tzinfo: tzInfo })
        }
    }, [userData]);

    useEffect(() => {
        if (todos) {
            const formattedTodos = formatTodos(todos);
            setContext({ ...context, todos: formattedTodos })
        }
    }, [todos])

    /**
     * when command bar opens, pull:
     * - all projects
     * - all tasks (no route for this yet so doing todolist instead)
     * - user info (tzInfo)
     * 
     * Send all of that to the /beta/chat-start endpoint
     */

    const sendMessage = async (newMessages) => {
        try {
            const response = await startStream({
                messages: newMessages,
                context: context,
                now: new Date()
            });

            let isParsingJson = false; // Local flag to manage JSON parsing state

            const chatEventSource = new EventSource(
                `${process.env.REACT_APP_RENDER_BACKEND_URL}/beta/chat-stream?token=${Cookies.get('token')}&orgId=${Cookies.get('org')}`
            );

            chatEventSource.onmessage = event => {
                const data = JSON.parse(event.data);
                if (typeof data.content === 'string') {
                    if (data.content === "Here's the task to be scheduled: $$$[TASK]$$$") {
                        isParsingJson = true; // Set the flag before handling data
                        handleStreamedData("Here's the task to be scheduled:\n", 'assistant', isParsingJson);
                    } else if (data.content === `\Let me know when you've confirmed the details and I'll schedule it for you.\n`) {
                        isParsingJson = false; // Set the flag before handling data
                        handleStreamedData(data.content, 'assistant', isParsingJson);
                    } else if (data.content === `$$**$$\n`) {
                        setTimeout(() => {
                            refresh();
                        }, 5000);
                    } else {
                        handleStreamedData(data.content, 'assistant', isParsingJson);
                    }
                } else {
                    console.error('Received non-string content:', data.content);
                }
            };

            chatEventSource.onerror = error => {
                console.error('EventSource failed:', error);
                chatEventSource.close();
            };
        } catch (error) {
            console.error(error);
        }
    };

    // Adjust handleStreamedData to accept the isParsingJson parameter
    const handleStreamedData = (content, source, isParsingJson) => {
        if (typeof content !== 'string') {
            console.error('Expected content to be a string, but got:', content);
            return;
        }

        setMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];

            if (lastMessage && lastMessage.role === source) {
                let updatedLastMessage;
                if (isParsingJson) {
                    updatedLastMessage = {
                        ...lastMessage,
                        json: (lastMessage.json || '') + content // Ensure json field exists
                    };
                } else {
                    updatedLastMessage = {
                        ...lastMessage,
                        content: lastMessage.content + content
                    };
                }
                return [...prevMessages.slice(0, -1), updatedLastMessage];
            } else {
                return [...prevMessages, { role: source, content }];
            }
        });
    };


    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            const newMessages = [...messages, { role: 'user', content: inputValue }]
            setMessages(newMessages)
            setInputValue('')
            await sendMessage(newMessages)
            setParsingJson(false)
        }
    }

    const renderChat = () => {
        return messages.map((message, index) => (
            <div
                key={index}
                className={`p-3 my-2 rounded-lg ${message.role === 'user'
                    ? ' bg-main-purple text-white ml-auto mr-3'
                    : ' bg-gray-300 text-black mr-auto w-content ml-3'
                    }`}
                style={{ maxWidth: '80%' }}
            >
                {message.loading ? (
                    <Spinner />
                ) : (
                    <div>
                        <p className='text-md' dangerouslySetInnerHTML={{ __html: message.content.replace(/\n/g, '<br>') }}></p>
                        {message.json && (
                            <div className='w-[48rem]'>
                                <CommandBarTask content={message.json} />
                            </div>

                        )}
                    </div>
                )}
            </div>
        ))
    }


    return (
        <Modal open={isVisible} setOpen={setIsVisible}>
            <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl">
                {messages.length > 0 && (
                    <>
                        <div className='h-auto max-h-96 mb-5 overflow-y-scroll flex flex-col'>
                            {renderChat()}
                            <div ref={chatEndRef} />
                        </div>

                        <div className='relative'>
                            <div
                                className='absolute inset-0 flex items-center'
                                aria-hidden='true'
                            >
                                <div className='w-full border-t border-gray-300' />
                            </div>
                        </div>
                    </>
                )}

                <div className='mx-auto flex h-16 w-full items-center justify-center'>
                    <img
                        className='h-12 w-auto p-2'
                        src={Logo}
                        alt='ApolloAssistant'
                    />

                    <input
                        type='text'
                        name='commandInput'
                        id='commandInput'
                        className='block rounded-lg w-full h-full border-0 focus:ring-0 focus:outline-none text-gray-900 placeholder:text-gray-400 sm:text-md sm:leading-6'
                        placeholder='Try: Find an hour for pitch deck review'
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                </div>

            </Dialog.Panel>
        </Modal>
    )
}

export default CommandBar