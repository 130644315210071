import React from "react";

const Pill = ({ children, variant }) => {
    const variantStyles = {
        green: 'bg-green-50 text-green-700 ring-green-600/20',
        yellow: 'bg-yellow-50 text-yellow-700 ring-yellow-600/20',
        orange: 'bg-orange-50 text-orange-700 ring-orange-600/20',
        red: 'bg-red-50 text-red-700 ring-red-600/20',
        gray: 'bg-gray-50 text-gray-700 ring-gray-600/20',
    };

    return (
        <span
            className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium ring-1 ring-inset ${variantStyles[variant]}`}
        >
            {children}
        </span>
    );
}

export default Pill;