import React, { useRef } from 'react'
import { DatePicker } from 'antd'
import TextInput from '../../Inputs/TextInput'
import DurationInput from '../../Inputs/DurationInput'
import Button from '../../Buttons/Button'
import PrioritySelector from '../../Dropdowns/PrioritySelector'
import { XMarkIcon } from '@heroicons/react/20/solid';


const ConfigureProject = ({
    project,
    setProject,
    handleNext,
    isDisabled,
    isLoading,
    setIsVisible }) => {

    const fileInputRef = useRef(null);

    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setProject({ ...project, file: file })
        e.target.value = null
    }

    const removePdf = () => {
        setProject({ ...project, file: null })
        fileInputRef.current.value = null;
    }

    return (
        <div className="flex flex-col bg-white">
            <div className="flex justify-between items-center mb-1">
                <h2 className="text-xl font-bold">New Project</h2>
                <div className="flex items-center w-1/12">
                    <Button variant="neutral" className={'px-0 text-xs'} onClick={() => setIsVisible(false)}>Cancel</Button>
                </div>
            </div>
            <p className='text-sm text-gray-600 mb-3'>Add some details about your new project. We’ll use AI to help plan it out in the next step. </p>
            <div className='w-4/5'>
                <TextInput
                    id='project-name'
                    type='text'
                    placeholder='Write a project name'
                    value={project.name}
                    label='Project Name'
                    onChange={e => setProject({ ...project, name: e.target.value })}
                    className='mb-4'
                />
            </div>
            <div className='flex items-center justify-start'>
                <div className='flex flex-col w-1/5'>
                    <label htmlFor="start-date" className="text-sm font-medium text-gray-900 mb-2">Start Date</label>
                    <DatePicker
                        id='start-date'
                        use12Hours
                        changeOnBlur
                        minuteStep={5}
                        popupClassName='noFooterTimePick'
                        showTime={{
                            format: 'h:mm a',
                        }}
                        value={project.startDate}
                        format="MM/DD/YYYY h:mm a"
                        allowClear={false}
                        onChange={value => {
                            setProject({ ...project, startDate: value });
                        }}
                        className='h-8'
                    />
                </div>
                <div className='ml-5 flex flex-col w-1/5'>
                    <label htmlFor="end-date" className="text-sm font-medium text-gray-900 mb-2">Deadline</label>
                    <DatePicker
                        id='end-date'
                        use12Hours
                        changeOnBlur
                        minuteStep={5}
                        popupClassName='noFooterTimePick'
                        showTime={{
                            format: 'h:mm a',
                        }}
                        value={project.dueDate}
                        format="MM/DD/YYYY h:mm a"
                        onChange={value => {
                            console.log(value)
                            console.log(value.format('YYYY-MM-DDTHH:mm:ssZ'))
                            console.log(value.utc().format('YYYY-MM-DDTHH:mm:ssZ'))
                            setProject({ ...project, dueDate: value });
                        }}
                        className='h-8'
                        allowClear={false}
                    />
                </div>
                <div className='ml-5 flex flex-col w-1/5'>
                    <label htmlFor="time-needed" className="text-sm font-medium text-gray-900">Time Needed <span className='font-light'>(est)</span></label>
                    <DurationInput
                        className='w-56 h-8'
                        placeholder='1 hr 30 min'
                        value={project.timeNeeded}
                        onChange={e => setProject({ ...project, timeNeeded: e })}
                    />
                </div>
                <div className='ml-7 flex flex-col w-1/5'>
                    <label htmlFor="priority" className="ml-5 text-sm font-medium text-gray-900">Priority</label>
                    <PrioritySelector priority={project.priority} setPriority={value => setProject({ ...project, priority: value })} />
                </div>

            </div>
            <div className='mt-3'>
                <label className="text-md font-medium text-gray-900">Project description</label>
                <p className='text-sm text-gray-600 mt-2 mb-2'>What’s this project about? If you’ve already started, feel free to provide details. You can also{' '}
                    <span
                        className='text-main-purple font-semibold cursor-pointer hover:underline'
                        onClick={handleFileUploadClick}
                    >
                        upload a file.
                    </span>
                </p>

                {project.file && (
                    <div className='text-sm text-gray-600 mb-3 w-11/12 flex items-center'>
                        Uploaded file: {project.file.name}
                        <XMarkIcon className='text-gray-400  cursor-pointer hover:text-red-400 ml-2 h-5 w-5' onClick={removePdf} />
                    </div>
                )}
                <input
                    type="file"
                    accept=".pdf"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
                <textarea
                    rows={5}
                    name="preferences"
                    id="preferences"
                    className="block w-full  resize-none border border-gray-200 p-2  rounded-lg text-gray-900 placeholder:text-gray-400 focus:border-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="What's this project about?"
                    value={project.description}
                    onChange={(e) => setProject({ ...project, description: e.target.value })}
                />
            </div>
            <div className="flex justify-end mt-3">
                <div className="flex w-1/5">
                    <Button variant="additive" disabled={isDisabled()} onClick={handleNext} loading={isLoading} className="w-1/4">Next →</Button>
                </div>
            </div>
        </div>
    )
}

export default ConfigureProject