import React from 'react'

const SplitWithImageRight = ({ content, image }) => {
  return (
    <div className='flex min-h-screen flex-col md:flex-row'>
      {/* Content side - centered on mobile */}
      <div className='w-full md:w-1/2 flex items-center justify-center md:mt-0 mt-32'>
        <div className='max-w-md mx-auto'>{content}</div>
      </div>

      {/* Image side - hidden on mobile, shown on medium screens and up */}
      <div className='hidden md:block md:w-1/2'>
        <img
          src={image}
          alt='Split screen'
          className='object-cover w-full h-screen'
        />
      </div>
    </div>
  )
}

export default SplitWithImageRight