import React, { useState } from 'react'
import dayjs from 'dayjs'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import { DatePicker } from 'antd'
import PlanProject from '../Modals/Views/PlanProject'
import TextInput from '../Inputs/TextInput'
import DurationInput from '../Inputs/DurationInput'
import ProgressSelector from '../Dropdowns/ProgressSelector'

const projectColorLookup = {
    'purple': 'bg-event-bg-purple',
    'green': 'bg-event-bg-green',
    'yellow': 'bg-event-bg-yellow',
    'blue': 'bg-event-bg-blue',
    'red': 'bg-event-bg-red',
}

const ProjectTable = ({
    projects,
    setProjects,
    teamMembers,
    setChangedProjects,
    setChangedTasks,
    setAddedTasks,
    setDeletedTasks }) => {
    const [selectedProject, setSelectedProject] = useState(null);

    const updateProject = (projectId, updatedFields, additiveOrDestructive = null, id = null) => {
        const updatedProjects = projects.map(project => {
            if (project.id === projectId) {
                if (additiveOrDestructive === 'add') {
                    setAddedTasks(prevState => [...prevState, id]);
                }
                else if (additiveOrDestructive === 'delete') {
                    setDeletedTasks(prevState => [...prevState, id]);
                }
                else {
                    // Check if the assignee has changed for any task
                    const changedTaskIds = updatedFields.tasks
                        .filter((task, index) => task.assignee?.userId !== project.tasks[index]?.assignee?.userId)
                        .map(task => task.id);

                    // If there are any changed tasks, add them to the changedTasks state
                    if (changedTaskIds.length > 0) {
                        setChangedTasks(prevState => [...prevState, ...changedTaskIds]);
                    }

                    // If the project or any of its tasks have changed, add it to the changedProjects state
                    if (Object.keys(updatedFields).length > 0 || changedTaskIds.length > 0) {
                        setChangedProjects(prevState => [...prevState, projectId]);
                    }
                }

                return { ...project, ...updatedFields };
            } else {
                return project;
            }
        });

        setProjects(updatedProjects);
    };

    return (
        <table className="min-w-full divide-y divide-gray-300 shadow-lg">
            <thead className="bg-gray-100 rounded-tl-lg rounded-tr-lg">
                <tr>
                    <th scope="col" className="w-6/12 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 rounded-tl-lg">
                        Name
                    </th>
                    <th scope="col" className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                    </th>
                    <th scope="col" className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Time Needed
                    </th>
                    <th scope="col" className="w-1/6 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 rounded-tr-lg">
                        Deadline
                    </th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {projects.map((project) => (
                    <React.Fragment key={project.id}>
                        <tr
                            onClick={project.id !== selectedProject ? () => setSelectedProject(project.id) : null}
                            className={`${project.id !== selectedProject ? 'cursor-pointer w-full' : 'cursor-auto w-full'}`}
                        >
                            <td className="w-6/12 whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
                                <div className="flex items-center">
                                    <div className="font-semibold text-gray-900 w-full flex items-center">
                                        <div className={`h-3 w-3 rounded-full ml-3 mt-2.5 ${projectColorLookup[project.color]}`}>
                                        </div>

                                        <div className='w-full mr-2'>
                                            <TextInput
                                                id='task-name'
                                                type='text'
                                                placeholder='Write a project name'
                                                value={project.name}
                                                onChange={e => updateProject(project.id, { ...project, name: e.target.value })}
                                                className='!cursor-pointer focus:!cursor-auto !ring-0 !shadow-none focus:!border-b-1 focus:!border-indigo-500 focus:!ring-1 mt-0'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className="w-1/12 whitespace-nowrap text-sm -pl-3 text-gray-500">
                                <ProgressSelector
                                    progress={project.status}
                                    setProgress={value => updateProject(project.id, { ...project, status: value })}
                                />

                            </td>
                            <td className="w-1/6 whitespace-nowrap px-3 py-5 text-sm font-medium text-gray-900">
                                <DurationInput
                                    className='w-44 h-7 !cursor-pointer focus:!cursor-auto !ring-0 !shadow-none focus:!border-b-1 focus:!border-indigo-500 focus:!ring-1 mt-0'
                                    placeholder='1 hr 30 min'
                                    value={project.timeNeeded}
                                    onChange={e => updateProject(project.id, { ...project, timeNeeded: e })}
                                />
                            </td>
                            <td className="w-1/6 whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                <div className="flex items-center">
                                    <div className="font-medium text-gray-900 ">
                                        <DatePicker
                                            id='deadline'
                                            use12Hours
                                            changeOnBlur
                                            minuteStep={5}
                                            popupClassName='noFooterTimePick'
                                            showTime={{
                                                format: 'h:mm a',
                                            }}
                                            value={dayjs(project.deadline)}
                                            format="MM/DD/YYYY h:mm a"
                                            onChange={value => updateProject(project.id, { ...project, deadline: value })}
                                            className='h-8 ring-0 border-0 focus:ring-1 focus:border-indigo-500 text-left'
                                            allowClear={false}
                                        />
                                    </div>
                                    <div className="ml-auto">
                                        {selectedProject === project.id ? <ChevronUpIcon className="h-5 w-5 cursor-pointer hover:text-gray-900" onClick={() => setSelectedProject(null)} /> : <ChevronDownIcon className="h-5 w-5" />}
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <AnimatePresence>
                            {selectedProject === project.id && (
                                <motion.tr
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: "auto" }}
                                >
                                    <td colSpan={4} className='border-0'>
                                        <div className="w-full h-full p-3">
                                            <PlanProject
                                                project={project}
                                                setProject={(updatedProject, additiveOrDestructive, id) => updateProject(project.id, updatedProject, additiveOrDestructive, id)}
                                                teamMembers={teamMembers}
                                                showDelete={true}
                                                showDetailsButton={true}
                                            />
                                        </div>
                                    </td>
                                </motion.tr>
                            )}
                        </AnimatePresence>
                    </React.Fragment>
                ))}
            </tbody>
        </table>
    )
}


export default ProjectTable
