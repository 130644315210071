import React, { useState } from "react";
import { Dialog } from '@headlessui/react'
import dayjs from "dayjs";
import Modal from "./Modal";
import InputBrainDump from "../Views/InputBrainDump";
import ConfirmBrainDump from "../Views/ConfirmBrainDump";
import usePost from "../../../hooks/usePost";

const BrainDump = ({
    isVisible,
    setIsVisible,
    refresh,
    setNotificationBody,
    setShowNotification,
    refreshTodos }) => {
    const [view, setView] = useState('input')
    const [braindumpContent, setBraindumpContent] = useState('')
    const [actionItems, setActionItems] = useState(null)
    const [waitingText, setWaitingText] = useState('')
    const [pdfFile, setPdfFile] = useState(null)
    const [formatBraindump, , formatBraindumpIsLoading, formatBraindumpError] = usePost('/beta/braindump', null, process.env.REACT_APP_RENDER_BACKEND_URL);

    const text = `Analyzing the details you've provided...     
    \nDeveloping a plan to align with your objectives and get everything done on time...     
    \nStructuring tasks for ideal organization and efficiency...     
    \nCustomizing plans to fit your unique schedule and preferences...     
    \nFinal preparations underway to present your tailored task list...     
    \nIn a few moments, you will be able to edit and confirm the tasks, ensuring they perfectly align with your goals and timeframes...     `;
    let i = 0;
    let typingInterval;
    const startTypingEffect = () => {
        typingInterval = setInterval(() => {
            setWaitingText(prevText => {
                // Determine the next character index based on the previous text length
                const nextCharIndex = prevText.length;
                // If the next character index is less than the text length, append the next character
                if (nextCharIndex < text.length) {
                    return prevText + text.charAt(nextCharIndex);
                } else {
                    // If we've reached the end of the text, clear the interval
                    clearInterval(typingInterval);
                    return prevText; // Return the previous text to avoid changes
                }
            });
        }, 75);
    };
    const stopTypingEffect = () => {
        clearInterval(typingInterval);
    };
    // startTypingEffect();

    const handleNext = async () => {
        try {
            setTimeout(() => {
                setView('configure');
                startTypingEffect();
            }, 5000);

            const formData = new FormData();
            formData.append('braindumpContent', braindumpContent);
            formData.append('now', dayjs().format('YYYY-MM-DDTHH:mm:ssZ'));
            if (pdfFile) {
                formData.append('pdf', pdfFile);
            }

            const response = await formatBraindump(formData);


            // Once the data is returned, set actionItems, stop the typing effect, and set waitingText
            setActionItems(response.data);
            stopTypingEffect();
            setWaitingText("Apollo found these projects & tasks that you need to work on based on your brain dump. Review them and then we’ll use AI to schedule work time for them.");
        } catch (error) {
            console.error(error);
            setNotificationBody({
                title: "Error in braindump",
                subtext: "Something went wrong. Please try again.",
                positive: false,
            });
            setShowNotification(true);
        }
    }

    const clear = () => {
        setBraindumpContent('')
        setActionItems(null)
        setView('input')
    }

    const isDisabled = () => {
        return braindumpContent === '' && !pdfFile
    }
    return (
        <Modal open={isVisible} setOpen={setIsVisible}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-5">
                {view === 'input' ?
                    <InputBrainDump
                        setIsVisible={setIsVisible}
                        handleNext={handleNext}
                        isDisabled={isDisabled}
                        isLoading={formatBraindumpIsLoading}
                        brainDumpContent={braindumpContent}
                        setBrainDumpContent={setBraindumpContent}
                        setPdf={setPdfFile}
                        pdfFile={pdfFile}
                    />
                    :
                    <ConfirmBrainDump
                        actionItems={actionItems}
                        setActionItems={setActionItems}
                        refresh={refresh}
                        refreshTodos={refreshTodos}
                        setShowNotification={setShowNotification}
                        setNotificationBody={setNotificationBody}
                        setIsVisible={setIsVisible}
                        waitingText={waitingText}
                        clear={clear}

                    />
                }
            </Dialog.Panel>

        </Modal>
    )
}

export default BrainDump