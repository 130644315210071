import axios from 'axios';
import { useState } from 'react';
import Cookies from 'js-cookie';

const usePost = (url, data, baseURL) => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const api = axios.create({
        baseURL: baseURL || process.env.REACT_APP_AWS_BACKEND_URL,
    });

    api.interceptors.request.use((config) => {
        const token = Cookies.get('token');
        config.headers.token = token ? `${token}` : '';
        return config;
    });

    const postData = async (data) => {
        setIsLoading(true);
        try {
            const res = await api.post(url, data);
            setResponse(res.data);
            setIsLoading(false);
            return res; // return the response
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Check if the redirect cookie is set
                const isRedirected = Cookies.get("isRedirected");

                // Only proceed if not already redirected
                if (!isRedirected) {
                    Cookies.remove("token");
                    Cookies.remove("org");
                    // Set a cookie to indicate that a redirect has occurred
                    Cookies.set("isRedirected");
                    window.location.reload();
                }
            }
            setError(error);
            setIsLoading(false);
            throw error; // throw the error so it can be caught in the component
        }
    };

    return [postData, response, isLoading, error];
};

export default usePost;