import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const AssigneeSelector = ({ assignee, setAssignee, assignees }) => {

    return (
        <Menu as="div" className="relative w-full h-full inline-block text-center">
            <div>
                <Menu.Button className="inline-flex  w-full justify-center gap-x-1.5 rounded-md bg-white py-2 text-sm font-normal text-gray-900 hover:bg-gray-50">
                    <p className='text-xs'>{assignee.name}</p>
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                {/* <Menu.Items className="absolute  transform -translate-x-1/2 mt-2 px-2 origin-top rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"> */}
                <Menu.Items className="absolute left-1/2 z-10 mt-2 transform -translate-x-1/2 px-2 origin-top- rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        {assignees.map((assignee) => (
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => setAssignee(assignee)}
                                        className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'block w-full  py-2 text-left text-sm text-left'
                                        )}
                                    >
                                        <p className='text-xs'>{assignee.name}</p>
                                    </button>
                                )}
                            </Menu.Item>
                        )
                        )}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default AssigneeSelector