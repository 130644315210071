import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import SplitWithImageRight from '../layouts/SplitWithImageRight'
import IntegrationButton from '../components/Buttons/IntegrationButton'
import LoginImage from '../images/LoginImage.png'
import Logo from '../images/Logo.png'

const Login = () => {
  const [inviteToken, setInviteToken] = useState(null)
  const [textMode, setTextMode] = useState(null)
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const inviteToken = params.get('inviteToken');
    const text = params.get('text')
    if (inviteToken) {
      setInviteToken(inviteToken)
    }

    if (text) {
      setTextMode(true)
    }

    const token = params.get('token');
    if (token) {
      Cookies.set('token', token);
    }
    const org = params.get('org');
    if (org) {
      Cookies.set('org', org);
    }

  }, [location]);

  const content = (
    <div>
      <div className='flex flex-col items-center mb-4'>
        <img src={Logo} alt='Logo' className='mb-2 w-16 h-16' />
        <h1 className='text-4xl font-bold'>Welcome to Astra!</h1>
        <p className='mt-6'>Connect a calendar to get started.</p>
      </div>

      <div className='flex flex-col gap-4'>
        <IntegrationButton
          text='Connect with Google Calendar'
          icon='https://upload.wikimedia.org/wikipedia/commons/a/a5/Google_Calendar_icon_%282020%29.svg'
          redirectUrl={`https://www.apolloassistant.dev/auth/google${inviteToken ? `?inviteToken=${inviteToken}` : ''}${textMode ? '?text=1' : ''}`}
        />
        <IntegrationButton
          text='Connect with Outlook Calendar'
          icon='https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg'
          redirectUrl={`${process.env.REACT_APP_RENDER_BACKEND_URL}/auth/outlook${inviteToken ? `?inviteToken=${inviteToken}` : ''}${textMode ? '?text=1' : ''}`}
        />
      </div>
    </div>
  )

  return <SplitWithImageRight content={content} image={LoginImage} />
}

export default Login
