import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import useFetch from '../hooks/useFetch'
import usePost from '../hooks/usePost';
import ThinSidebar from '../layouts/ThinSidebar'
import TodoListSidebar from '../layouts/TodoListSidebar'
import MainCalendar from '../components/Calendars/MainCalendar'
import CreateTask from '../components/Modals/Modals/CreateTask'
import CommandBar from '../components/Modals/CommandBar/CommandBar'
import BrainDump from '../components/Modals/Modals/BrainDump';
import StatusNotification from '../components/Notifications/StatusUpdate';
import convertNumberToPriority from '../utils/formatters/NumberToPriority';
import LoadingBanner from '../components/Notifications/Loading';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatEvents = (events) => {
  try {
    return events.events.map(event => ({
      ...event,
      start: new Date(event.begin),
      end: new Date(event.end),
      title: event.name
    }));

  } catch (error) {
    // console.log(error);
    return [];
  }
}

const formatTodos = (todos) => {
  let formattedTodos = [];
  for (let date in todos) {
    if (todos[date].length > 0) {
      let apolloEvents = todos[date].filter(event => event.originalSource === 'apollo');
      if (apolloEvents.length > 0) {
        formattedTodos.push({
          day: dayjs(date).format("ddd, MMM D"),
          todos: apolloEvents.map(event => ({
            stableId: event.stableId,
            name: event.name,
            startTime: dayjs(event.begin).format("h:mm a"),
            endTime: dayjs(event.end).format("h:mm a"),
            daysUntilDue: event.daysLeft,
            completed: event.complete,
            priority: convertNumberToPriority(event.priority),
          }))
        });
      }
    }
  }
  return formattedTodos;
}


const Home = () => {
  const [createTaskOpen, setCreateTaskOpen] = useState(false)
  const [commandCenterOpen, setCommandCenterOpen] = useState(false)
  const [brainDumpOpen, setBrainDumpOpen] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationBody, setNotificationBody] = useState(null)
  const [displayTodos, setDisplayTodos] = useState([])

  const navigate = useNavigate()
  const location = useLocation();

  const threeWeekWindow = useMemo(() => ({ begin: dayjs().subtract(5, 'week').format('YYYY-MM-DD'), end: dayjs().add(10, 'week').format('YYYY-MM-DD') }), []);
  const additionalParams = useMemo(() => ({ begin: dayjs().format('YYYY-MM-DD'), end: dayjs().add(1, 'week').format('YYYY-MM-DD'), tzinfo: dayjs.tz.guess() }), []);
  const { data: events, loading, error, refresh } = useFetch('/user/list-blocks', threeWeekWindow)
  const { data: todos, loading: todosLoading, error: todosError, refresh: todosRefresh } = useFetch('/user/todo-list', additionalParams)
  const [deleteEvent, , deleteEventIsLoading, deleteEventError] = usePost('/event/delete')
  const [completeTodo, , completeTodoIsLoading, completeTodoError] = usePost('/timeblock/complete')

  useEffect(() => {
    if (error) {
      console.error(error)
      setNotificationBody({
        title: "Error refreshing calendar",
        subtext: "Please try again",
        positive: false,
      })
      setShowNotification(true)
    }
  }, [events, loading, error]);

  useEffect(() => {
    setDisplayTodos(formatTodos(todos))
  }, [todos])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const onboarding = params.get('onboarding');
    if (onboarding) {
      setBrainDumpOpen(true)
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    Cookies.remove("isRedirected");
  }, []);

  const updateCalendar = async (inputDate) => {
    try {
      // TODO: Make api call
    } catch (error) { }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
      setCommandCenterOpen(true);
    }
  };

  const refreshAll = () => {
    refresh()
    todosRefresh()
  }

  const handleCompleteTodo = async (stableId) => {
    const updatedTodos = displayTodos.map(todo =>
      todo.stableId === stableId ? { ...todo, completed: true } : todo
    );
    const response = await completeTodo({
      stableId: stableId,
      orgId: Cookies.get("org")
    })
    setNotificationBody({
      title: "Item marked complete",
      // subtext: "Please try again",
      positive: true,
    })
    setShowNotification(true)
    setDisplayTodos(updatedTodos);
  }

  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await deleteEvent({
        eventId
      })
      setNotificationBody({
        title: "Event deleted successfully",
        // subtext: "Please try again",
        positive: true,
      })
      setShowNotification(true)
      refresh()
      todosRefresh()
    } catch (error) {
      console.error(error)
      setNotificationBody({
        title: "Error deleting event",
        subtext: "Please try again",
        positive: false,
      })
      setShowNotification(true)
    }
  }

  return (
    <>
      <div className='relative'>
        <CreateTask
          isVisible={createTaskOpen}
          setIsVisible={setCreateTaskOpen}
          refresh={refresh}
          setNotificationBody={setNotificationBody}
          setShowNotification={setShowNotification}
          refreshTodos={todosRefresh}
        />
        <CommandBar isVisible={commandCenterOpen} setIsVisible={setCommandCenterOpen} refresh={refreshAll} />
        <BrainDump
          isVisible={brainDumpOpen}
          setIsVisible={setBrainDumpOpen}
          refresh={refresh}
          setNotificationBody={setNotificationBody}
          setShowNotification={setShowNotification}
          refreshTodos={todosRefresh}
        />
        <ThinSidebar
          content={
            <MainCalendar
              setShowCommandBar={() => setCommandCenterOpen(true)}
              events={formatEvents(events)}
              toggleAddEvent={[]}
              update={updateCalendar}
              deleteEvent={handleDeleteEvent}
            />
          }
          thickSidebar={
            <TodoListSidebar
              todos={displayTodos}
              completeTodo={handleCompleteTodo}
              showAddTodo={setCreateTaskOpen}
              navigateToProject={() => navigate("/projects?open=true")}
              showAddBrainDump={() => setBrainDumpOpen(true)}
            />
          }
          currentIdx={0} />
      </div>
      <StatusNotification show={showNotification} setShow={setShowNotification} content={notificationBody} />
      <LoadingBanner show={loading || todosLoading || deleteEventIsLoading || completeTodoIsLoading} />
    </>

  )
}

export default Home

