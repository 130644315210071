import React, { useState, useEffect, useMemo } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import Login from './pages/Login'
import InviteTeam from './pages/InviteTeam'
import Home from './pages/Home'
import Projects from './pages/Projects'
import SetWorkingHours from './pages/SetWorkingHours'
import WorkPreferences from './pages/WorkPreferences'
import Settings from './pages/Settings'
import NewAccount from './pages/NewAccount'
import useFetch from './hooks/useFetch'
import ApproveProject from './pages/ApproveProject'
import TextSuccess from './pages/TextSuccess'
import TextSetup from './pages/TextSetup'


const formatWorkingHoursForEdit = (formattedWorkingHours) => {
  const workingHours = [];
  Object.entries(formattedWorkingHours).forEach(([day, hours]) => {
    workingHours.push({
      day,
      hours: hours.map(({ start, end }) => ({
        id: uuidv4(),
        start,
        end
      })),
    });
  });
  return workingHours;
};

const formatWorkingHours = (workingHours) => {
  const dayMapping = {
    'sunday': 'Sun',
    'monday': 'Mon',
    'tuesday': 'Tue',
    'wednesday': 'Wed',
    'thursday': 'Thu',
    'friday': 'Fri',
    'saturday': 'Sat',
  };

  const formattedWorkingHours = {};
  Object.entries(workingHours).forEach(([day, hours]) => {
    if (day !== 'tzinfo') {
      formattedWorkingHours[dayMapping[day]] = hours.map(([start, end]) => ({
        start: dayjs(start, 'HH:mm:ss').format('h:mm a'),
        end: dayjs(end, 'HH:mm:ss').format('h:mm a'),
      }));
    }
  });
  return formattedWorkingHours;
};

const formatTeam = (teamMembers) => {
  try {
    return teamMembers?.users.map(member => ({
      name: member.name,
      userId: member.userId
    }))
  } catch (error) {
    console.error(error)
    return []
  }
}

const App = () => {
  const [authToken, setAuthToken] = useState(null)
  const [preferences, setPreferences] = useState('')
  const [workingHours, setWorkingHours] = useState({})
  const [team, setTeam] = useState([])
  const [firstName, setFirstName] = useState(null)
  const additionalEmptyParams = useMemo(() => ({}), []);
  const { data: userData, isLoading: userDataIsLoading, error: userDataError, refresh: refreshUser } = useFetch('/user/get', additionalEmptyParams)
  const { data: teamMembers, loading, error, refresh: refreshTeam } = useFetch('/org/list-users', additionalEmptyParams)

  useEffect(() => {
    const token = Cookies.get('token')
    if (token) {
      setAuthToken(token)
    }
  }, [])

  useEffect(() => {
    setPreferences(userData?.user.preferences)
  }, [userData])

  useEffect(() => {
    const firstName = userData?.user.name.split(' ')[0];
    setFirstName(firstName);
  }, [userData])

  useEffect(() => {
    if (userData?.user.workingHours) {
      const formattedWorkingHours = formatWorkingHours(userData.user.workingHours);
      setWorkingHours(formattedWorkingHours);
    }
  }, [userData]);

  useEffect(() => {
    if (teamMembers) {
      const formattedTeam = formatTeam(teamMembers);
      setTeam(formattedTeam);
    }
  }, [teamMembers]);

  useEffect(() => {
    // Set up an interval to reload the app every 6 hours
    const intervalId = setInterval(() => {
      window.location.reload();
    }, 21600000); // 6 hours in milliseconds

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const refreshAllSettings = () => {
    refreshTeam();
    refreshUser();
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={authToken ? <Home /> : <Navigate to='/login' />}
        />
        <Route
          path='/login'
          element={authToken ? <Navigate to='/' /> : <Login />}
        />
        <Route
          path='/new-account'
          element={<NewAccount />}
        />
        <Route
          path='/new-account/invite-team'
          element={<InviteTeam />}
        />
        {/* I think probably need a route for when you have an invite code */}
        <Route
          path='/new-account/working-hours'
          element={<SetWorkingHours newAccount={true} name={firstName} />}
        />
        <Route
          path='/new-account/text'
          element={<TextSetup name={firstName} />}
        />
        <Route
          path='/new-account/preferences'
          element={<WorkPreferences />}
        />
        <Route
          path='/working-hours'
          element={<SetWorkingHours newAccount={false} existingWorkingHours={formatWorkingHoursForEdit(workingHours)} name={firstName} />}
        />
        <Route
          path='/projects/*'
          element={<Projects />}
        />
        <Route
          path='/settings'
          element={<Settings
            existingTeamMembers={team}
            existingWorkingHours={workingHours}
            existingPreferences={preferences}
            removeAuthToken={() => setAuthToken(null)}
            refresh={refreshAllSettings}
          />}
        />
        <Route
          path='/approve'
          element={<ApproveProject />}
        />
        <Route
          path='/text-success'
          element={<TextSuccess />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
