import React, { useState } from 'react';
import TextInput from './TextInput';

const DurationInput = (props) => {
    // const [value, setValue] = useState('');
    const [invalid, setInvalid] = useState(false);
    const value = props.value;
    const setValue = props.onChange;

    const onBlur = () => {
        const words = value.split(' ').filter(word => word !== '');
        const numbers = words.filter(word => !isNaN(word));
        let formattedValue = '';
        let error = false;

        if (numbers.length === 1) {
            // Check if the input was in minutes
            if (words.includes('min') || words.includes('mins') || words.includes('minute') || words.includes('minutes') || words.includes('m') || words.includes('mi')) {
                formattedValue = `${numbers[0]} min`;
            } else {
                formattedValue = `${numbers[0]} hrs`;
            }
        } else if (numbers.length === 2) {
            formattedValue = `${numbers[0]} hrs ${numbers[1]} min`;
        } else {
            error = true;
        }

        setValue(formattedValue);
        setInvalid(error);
    };

    return (
        <TextInput
            {...props}
            value={value}
            invalid={invalid}
            errorMessage="Invalid duration format. Please enter in the format 'N hrs' or 'N hrs N min'."
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
        />
    );
};

export default DurationInput;