import React, { useState } from 'react';
import DurationInput from '../Inputs/DurationInput';
import AutoSizeTextInput from '../Inputs/AutoSizeTextInput';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useSwipeable } from 'react-swipeable';
import '../../utils/one-off-styles/antd.css';

const TaskApprovalContainer = ({ task, editTask, deleteTask }) => {
    const { RangePicker } = DatePicker;
    const [isSwiped, setIsSwiped] = useState(false);

    const handlers = useSwipeable({
        onSwipedLeft: () => setIsSwiped(true),
        onSwipedRight: () => setIsSwiped(false),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    return (
        <div {...handlers} className="relative flex flex-col p-2 bg-white shadow-md rounded-lg max-w-sm mx-auto">
            <div className={`transform transition-transform ${isSwiped ? '-translate-x-1/4' : 'translate-x-0'}`}>
                <div className="flex items-center justify-between">
                    <div className='w-auto'>
                        <div className="flex items-center justify-between">
                            <div className='w-auto'>
                                <div className="flex items-start justify-between">
                                    <div className='w-5/6 py-1.5'>
                                        <AutoSizeTextInput
                                            value={task.name}
                                            onChange={(value) => editTask({ ...task, name: value })}
                                            className="text-md font-semibold"
                                        />
                                    </div>
                                    <div className='w-1/3 mb-1'>
                                        <DurationInput
                                            value={task.timeNeeded}
                                            className="!m-0 !py-1 !px-1.5 !text-xs !shadow-none"
                                            placeholder='1 hr 30 min'
                                            onChange={e => editTask(task?.id, { ...task, timeNeeded: e })}
                                        />
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <RangePicker
                                        id='duration'
                                        className='text-xs'
                                        use12Hours
                                        changeOnBlur
                                        minuteStep={5}
                                        allowClear={false}
                                        popupClassName='noFooterTimePick'
                                        showTime={{
                                            format: 'h:mm a',
                                        }}
                                        value={
                                            [
                                                dayjs(task?.start).isValid() ? dayjs(task?.start) : undefined,
                                                dayjs(task?.deadline).isValid() ? dayjs(task?.deadline) : undefined
                                            ]
                                        }
                                        format="MM/DD/YYYY h:mm a"
                                        onChange={value => {
                                            editTask(task?.id, { ...task, start: value[0], deadline: value[1] });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isSwiped && (
                <div className="absolute w-1/4 inset-y-0 right-0 bg-red-500 flex items-center justify-center">
                    <XMarkIcon
                        className="h-6 w-6 text-white cursor-pointer"
                        onClick={() => {
                            deleteTask(task?.id);
                            setIsSwiped(false);
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default TaskApprovalContainer;