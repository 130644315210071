import React, { useState, useEffect } from 'react';
import StandaloneTaskConfigureContainer from '../../Containers/StandaloneTaskConfigureContainer';
import secondsToDuration from '../../../utils/formatters/SecondsToDuration';
import dayjs from 'dayjs';

const CommandBarTask = ({ content }) => {
    const initialTask = {
        name: "",
        begin: dayjs().toString(),
        deadline: dayjs().toString(),
        status: "Not Started",
        priority: "None",
        timeNeeded: "1 hr",
    };

    const [task, setTask] = useState(initialTask);

    const updateTask = (_, updatedFields) => {
        setTask({ ...task, ...updatedFields });

    }

    useEffect(() => {
        const attemptParseAndUpdateTask = (content) => {
            try {
                const parsedContent = JSON.parse(content);
                console.log(parsedContent);
                if (parsedContent.begin) parsedContent.begin = dayjs(parsedContent.begin).toDate();
                if (parsedContent.deadline) parsedContent.deadline = dayjs(parsedContent.deadline).toDate();
                if (parsedContent.timeNeeded) parsedContent.timeNeeded = secondsToDuration(parsedContent.timeNeeded);
                setTask(currentTask => ({ ...currentTask, ...parsedContent }));
            } catch {
                // If direct parse fails, modify content based on its ending
                let modifiedContent = content;
                if (modifiedContent.endsWith('"')) {
                    modifiedContent += '}';
                } else {
                    modifiedContent += '"}';
                }
                try {
                    const parsedContent = JSON.parse(modifiedContent);
                    setTask(currentTask => ({ ...currentTask, ...parsedContent }));
                } catch {
                    // If it still fails, the content is not ready to be parsed, ignore the error
                }
            }
        };

        attemptParseAndUpdateTask(content);
    }, [content]);

    return (
        <StandaloneTaskConfigureContainer
            task={task}
            setTask={updateTask}
            deleteTask={() => setTask(initialTask)}
        />
    );
};

export default CommandBarTask;