import React, { useCallback } from 'react';
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { format, startOfWeek, getDay, parse, setMinutes, setHours, setDay } from 'date-fns';
import { motion } from 'framer-motion'
import { v4 as uuidv4 } from 'uuid'
import { TrashIcon } from '@heroicons/react/24/outline'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import '../../utils/one-off-styles/WorkingHoursCalendar.css'



const DragAndDropCalendar = withDragAndDrop(Calendar)

const WorkingHoursCalendar = ({ workingHours, setWorkingHours }) => {
    const locales = {
        'en-US': require('date-fns/locale/en-US'),
    };

    const localizerWorkingHours = dateFnsLocalizer({
        format,
        startOfWeek,
        getDay,
        locales,
    });

    localizerWorkingHours.formats.dayFormat = 'EEE'
    localizerWorkingHours.formats.timeGutterFormat = (date, culture, localizer) =>
        localizer.format(date, 'h:mm a', culture).toLowerCase().replace(':00', '')
    const now = new Date()
    const minTime = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        5,
        0
    )
    const maxTime = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        21,
        59
    )


    const CustomEventComponent = ({ event }) => {
        const handleDeleteEvent = () => {
            setWorkingHours(prev =>
                prev.map(item => {
                    if (item.day === format(event.start, 'EEE')) {
                        item.hours = item.hours.filter(hour =>
                            hour.start !== format(event.start, 'h:mm a').toLowerCase() ||
                            hour.end !== format(event.end, 'h:mm a').toLowerCase()
                        )
                    }
                    return item
                })
            )
        }

        return (
            <motion.div whileHover={{ scale: 1.02 }} className='h-full p-1 relative group'>
                <TrashIcon onClick={handleDeleteEvent} className='group-hover:block hidden hover:text-red-600 h-5 w-5 absolute top-1 right-0 text-gray-500 cursor-pointer' />
                <p className='text-base font-bold w-2/3'>{event.title}</p>
                <p className='text-xs font-medium text-gray-600'>
                    {format(event.start, 'h:mm a').toLocaleLowerCase().replace(':00', '')} - {format(event.end, 'h:mm a').toLocaleLowerCase().replace(':00', '')}
                </p>
            </motion.div>
        )
    }

    const handleSelectSlot = useCallback(
        ({ start, end }) => {
            const dayOfWeek = format(start, 'EEE')
            const startHour = format(start, 'h:mm a').toLowerCase()
            const endHour = format(end, 'h:mm a').toLowerCase()

            setWorkingHours(prev =>
                prev.map(item => {
                    if (item.day === dayOfWeek) {
                        item.hours.push({
                            id: uuidv4(),
                            start: startHour,
                            end: endHour
                        })
                    }
                    return item
                })
            )
        },
        [setWorkingHours]
    )

    const resizeEvent = useCallback(
        ({ event, start, end }) => {
            const dayOfWeek = format(start, 'EEE')
            const startHour = format(start, 'h:mm a').toLowerCase()
            const endHour = format(end, 'h:mm a').toLowerCase()
            setWorkingHours(prev =>
                prev.map(item => {
                    if (item.day === dayOfWeek) {
                        item.hours = item.hours.map(hour => {
                            if (
                                hour.id === event.id
                            ) {
                                return { start: startHour, end: endHour }
                            }
                            return hour
                        })
                    }
                    return item
                })
            )
        },
        [setWorkingHours]
    )

    const moveEvent = useCallback(
        ({ event, start, end }) => {
            const dayOfWeek = format(start, 'EEE')
            const startHour = format(start, 'h:mm a').toLowerCase()
            const endHour = format(end, 'h:mm a').toLowerCase()

            setWorkingHours(prev =>
                prev.map(item => {
                    if (item.day === dayOfWeek) {
                        item.hours = item.hours.map(hour => {
                            if (
                                hour.id === event.id
                            ) {
                                return { start: startHour, end: endHour }
                            }
                            return hour
                        })
                    }
                    return item
                })
            )
        },
        [setWorkingHours]
    )


    const events = workingHours.flatMap(({ day, hours }) =>
        hours.map(({ start, end, id }) => {
            // Create a base date from the day of the week
            const baseDate = parse(day, 'EEE', new Date())

            // Create start and end dates by combining the base date with the hour's start and end times
            const startDate = parse(
                format(baseDate, 'yyyy-MM-dd') + 'T' + start,
                "yyyy-MM-dd'T'hh:mm a",
                new Date()
            )
            const endDate = parse(
                format(baseDate, 'yyyy-MM-dd') + 'T' + end,
                "yyyy-MM-dd'T'hh:mm a",
                new Date()
            )

            return {
                title: 'Working Hours',
                start: startDate,
                end: endDate,
                id: id,
                allDay: false
            }
        })
    )

    return (
        <div>
            <DragAndDropCalendar
                className='container-calendar container-onBoarding-calendar'
                defaultView={Views.WEEK}
                events={events}
                views={[Views.WEEK]}
                localizer={localizerWorkingHours}
                onSelectSlot={handleSelectSlot}
                selectable
                scrollToTime={setMinutes(setHours(setDay(new Date(), 4), 12), 0)}
                toolbar={false}
                onEventResize={resizeEvent}
                onEventDrop={moveEvent}
                components={{
                    event: CustomEventComponent,
                }}
                formats={{

                    eventTimeRangeFormat: () => {
                        return ''
                    }
                }}
                resizable
                popup
                step={15}
                style={{
                    position: 'relative',
                    height: '85vh',
                    overflow: 'auto',
                    backgroundColor: '#ffffff'
                }}
                timeslots={4}
                min={minTime}
                max={maxTime}
                showAllEvents={false}
                eventPropGetter={event => ({
                    className: 'working-hours'
                })}
            />
        </div>

    );
};

export default WorkingHoursCalendar;

