import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { XMarkIcon, PlusSmallIcon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom'
import usePost from '../hooks/usePost'
import TextInput from '../components/Inputs/TextInput'
import Button from '../components/Buttons/Button'
import ThinSidebar from '../layouts/ThinSidebar'
import StatusNotification from '../components/Notifications/StatusUpdate'

const Settings = ({
  existingTeamMembers,
  existingWorkingHours,
  existingPreferences,
  removeAuthToken,
  refresh
}) => {
  const [newTeamMembers, setNewTeamMembers] = useState([])
  const [newPreferences, setNewPreferences] = useState(existingPreferences)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationBody, setNotificationBody] = useState(null)

  const [setPreferencesResponse, , setPreferencesIsLoading, setPreferencesError] = usePost('/user/update-preferences')
  const [inviteTeam, , inviteTeamIsLoading, error] = usePost('/beta/invite-team', null, process.env.REACT_APP_RENDER_BACKEND_URL);

  const navigate = useNavigate()

  useEffect(() => {
    setNewPreferences(existingPreferences)
  }, [existingPreferences])

  useEffect(() => {
    refresh()
  }, [])
  const handleMemberChange = (index, value) => {
    setNewTeamMembers(members => {
      const newMembers = [...members]
      newMembers[index] = value
      return newMembers
    })
  }

  const removeMember = index => {
    setNewTeamMembers(members => {
      const newMembers = [...members]
      newMembers.splice(index, 1)
      return newMembers
    })
  }

  const addMoreEmailInputs = () => {
    setNewTeamMembers(members => [...members, ''])
  }

  const isDisabled = () => {
    return newTeamMembers.length === 0 && newPreferences === existingPreferences
  }

  const handleSignout = () => {
    Cookies.remove("token")
    Cookies.remove("org")
    removeAuthToken()
    navigate("/")
  }

  const handleSave = async () => {
    try {
      // Save preferences if they've changed
      if (newPreferences !== existingPreferences) {
        // Call API to save preferences
        const response = await setPreferencesResponse({
          preferences: newPreferences,
          orgId: Cookies.get('org')
        });
      }

      // Save team members if they've changed
      if (newTeamMembers.length !== existingTeamMembers.length) {
        // Call API to save team members
        const filteredTeamMembers = newTeamMembers.filter(member => member);
        const response = await inviteTeam({
          teamName: "Sinclair Public Affairs",
          emails: filteredTeamMembers,
          orgId: Cookies.get('org'),
        });
      }

      setShowNotification(true)
      setNotificationBody({
        title: "Settings saved",
        // subtext: "Your settings have been saved",
        positive: true,
      })

    } catch (error) {
      console.error(error)
      setShowNotification(true)
      setNotificationBody({
        title: "Error saving settings",
        subtext: "Please try again",
        positive: false,
      })
    }
  }



  const content = (
    <div className='p-6'>
      <div className="flex w-full justify-between">
        <div>
          <h1 className="text-3xl font-extrabold leading-7 text-gray-900">Settings</h1>
          <p className="mt-3  text-sm leading-6 text-gray-500">Adjust your organization's settings.</p>
        </div>
        <div className="">
          <div className='flex items-center justify-end  sm:mt-0  space-x-3'>
            <Button variant='neutral' onClick={() => navigate("/")}>
              Cancel
            </Button>
            <Button
              variant='additive'
              disabled={isDisabled()}
              onClick={handleSave}
              loading={inviteTeamIsLoading || setPreferencesIsLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Teammates</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <label

                className={`block text-sm font-medium text-gray-800`}
              >
                Email
              </label>
              {existingTeamMembers.map((existingMember, index) => (
                <p id={existingMember.userId} key={existingMember.userId} className="mt-2 max-w-2xl text-sm leading-6 text-gray-500">{existingMember.name}</p>
              ))}
              {newTeamMembers.length === 0 ? <div className='mt-1 w-24'>
                <Button variant='neutral' onClick={addMoreEmailInputs}>
                  + Invite
                </Button>
              </div> :
                newTeamMembers.map((member, index) => (
                  <div className='flex items-center justify-between' key={index}>
                    <div className='w-11/12'>
                      <TextInput
                        key={`member-${index}`}
                        id={`team-member-${index}`}
                        type='email'
                        placeholder='name@email.com'
                        value={member}
                        onChange={e => handleMemberChange(index, e.target.value)}
                      />
                    </div>
                    <PlusSmallIcon
                      onClick={addMoreEmailInputs}
                      className='h-6 w-6 text-gray-400 hover:text-green-400 cursor-pointer'
                    />
                    <XMarkIcon
                      onClick={() => removeMember(index)}
                      className='h-5 w-5 text-gray-400 hover:text-red-400 cursor-pointer'
                    />
                  </div>
                ))
              }
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <div>
              <div className='flex'>
                <dt className="text-sm font-medium leading-6 text-gray-900 mr-2">Working hours</dt>
                <a href="/working-hours" className="text-sm font-medium leading-6 text-blue-500 hover:underline">Edit</a>
              </div>
              <p className="text-sm  text-gray-500">Apollo will only schedule tasks during this time.</p>
            </div>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {Object.entries(existingWorkingHours).map(([day, hours]) => (
                <div key={day}>
                  <p className="font-medium">{day}</p>
                  {hours.length === 0 ? <p className="text-sm leading-6 text-gray-500">No availability</p> :
                    hours.map((hour, index) => (
                      <p key={index} className="text-sm leading-6 text-gray-500">{hour.start} - {hour.end}</p>
                    ))}
                </div>
              ))}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Preferences</dt>
            <textarea
              rows={3}
              name="preferences"
              id="preferences"
              className="block w-full bg-offwhite resize-none border-0 border-b border-transparent p-1 pb-2 text-gray-900 placeholder:text-gray-400 focus:border-indigo-600 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Schedule more intense work in the afternoons, no meetings after 3pm, etc..."
              value={newPreferences}
              onChange={(e) => setNewPreferences(e.target.value)}
            />
          </div>
          <div className="px-4 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <div>
              <dt className="text-sm font-medium leading-6 text-gray-900">Sign out</dt>
              <p className="text-sm  pr-16 text-gray-500">You will be logged out of this device and all unsaved changes will be lost.</p>
            </div>

            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div className='flex w-1/5'>
                <Button variant='destructive' onClick={handleSignout}>Sign out</Button>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )

  return (
    <>
      <ThinSidebar content={content} currentIdx={2} />
      <StatusNotification
        show={showNotification}
        setShow={setShowNotification}
        content={notificationBody}
      />
    </>
  )

}

export default Settings
