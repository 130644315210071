import React, { useRef } from 'react'
import Button from '../../Buttons/Button'
import { XMarkIcon } from '@heroicons/react/20/solid';
const InputBrainDump = ({
    setIsVisible,
    handleNext,
    isDisabled,
    brainDumpContent,
    setBrainDumpContent,
    setPdf,
    isLoading,
    pdfFile }) => {

    const fileInputRef = useRef(null);

    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setPdf(file);
        e.target.value = null
    }

    const removePdf = () => {
        setPdf(null);
        fileInputRef.current.value = null;
    }

    return (
        <div className="flex flex-col bg-white">
            <div className="flex justify-between items-center mb-1">
                <h2 className="text-xl font-bold">Brain dump</h2>
                <div className="flex items-center w-1/12">
                    <Button variant="neutral" className={'px-0 text-xs'} onClick={() => setIsVisible(false)}>Cancel</Button>
                </div>
            </div>
            <p className='text-sm text-gray-600 mb-3 w-11/12'>Take a moment to brain dump everything you have to do. Don't worry about organizing your thoughts, just get them all out. Apollo will organize them for you in the next step.</p>
            <p className='text-sm text-gray-600 mb-3 w-11/12'>
                You can also{' '}
                <span
                    className='text-main-purple font-semibold cursor-pointer hover:underline'
                    onClick={handleFileUploadClick}
                >
                    upload a file.
                </span>
            </p>
            {pdfFile && (
                <div className='text-sm text-gray-600 mb-3 w-11/12 flex items-center'>
                    Uploaded file: {pdfFile.name}
                    <XMarkIcon className='text-gray-400  cursor-pointer hover:text-red-400 ml-2 h-5 w-5' onClick={removePdf} />
                </div>
            )}            <input
                type="file"
                accept=".pdf"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <textarea
                rows={7}
                name="brainDumpContent"
                id="brainDumpContent"
                className="block w-full  resize-none border border-gray-200 p-2  rounded-lg text-gray-900 placeholder:text-gray-400 focus:border-gray-400 focus:ring-0 sm:text-sm sm:leading-6 mb-2"
                placeholder="Marketing campaign for new candidate, website redesign, etc..."
                value={brainDumpContent}
                onChange={(e) => setBrainDumpContent(e.target.value)}
            />
            <div className="flex justify-end">
                <div className="flex w-1/5">
                    <Button
                        variant="additive"
                        disabled={isDisabled()}
                        onClick={handleNext}
                        loading={isLoading}
                        className="w-1/4"
                    >
                        Next →
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default InputBrainDump