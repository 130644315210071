import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Spinner from '../LoadingWheels/Spinner';

const Button = ({ children, variant = 'neutral', onClick, disabled, loading, className, ...rest }) => {
  // Define base styling
  const baseStyle =
    'px-4 py-2 rounded-md shadow-sm font-medium text-sm leading-5 focus:outline-none w-full';

  // Variant-specific styling
  const variantStyles = {
    additive:
      'bg-main-purple text-white hover:bg-purple-800 ',
    destructive: 'bg-red-500 text-white hover:bg-red-600 ',
    neutral: 'bg-gray-300 text-gray-800 hover:bg-gray-400 '
  };

  const variantScales = {
    additive: { hover: 1.05, tap: 0.9 },
    destructive: { hover: 1.05, tap: 0.9 },
    neutral: { hover: 1.02, tap: 0.98 }
  };

  const disabledStyle = disabled || loading ? 'opacity-50 cursor-not-allowed' : '';

  return (
    <motion.button
      whileHover={{ scale: disabled ? 1.0 : variantScales[variant].hover }}
      whileTap={{ scale: disabled ? 1.0 : variantScales[variant].tap }}
      onClick={disabled || loading ? null : onClick}
      disabled={disabled || loading}
      className={`${baseStyle} ${variantStyles[variant]} ${className} ${disabledStyle}`}
      {...rest}
    >
      {loading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        children
      )}
    </motion.button>
  );
};

export default Button;
