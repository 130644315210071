import React, { useState, useEffect } from 'react';
import { motion, useSpring } from 'framer-motion';
import logo from '../images/Logo.png';
import SideImage from '../images/Simple-bg2.png';
import Button from '../components/Buttons/Button';
import SplitWithImageRight from '../layouts/SplitWithImageRight';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const NewAccount = () => {
    const scaleX = useSpring(0.1, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            Cookies.set('token', token);
        }
        const org = params.get('org');
        if (org) {
            Cookies.set('org', org);
        }

        navigate('/new-account/working-hours')
    }, [location]);

    const content = (
        <motion.div className='flex flex-col justify-between' initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}>
            <div className='mb-5'>
                <img src={logo} alt='Apollo Logo' className='mx-auto h-12 w-auto mb-5' />
                <h1 className='text-center text-4xl font-extrabold text-gray-900 mb-5'>
                    How do you want to use Apollo?
                </h1>
                <p className='text-left text-sm text-gray-600 mb-3'>
                    Use Apollo to manage your individual schedule, or to manage your team's schedule.
                </p>
                <div className='flex flex-col gap-4'>
                    <Button variant="additive" onClick={() => navigate('/new-account/working-hours')}>Manage my schedule</Button>
                    <Button variant="additive" onClick={() => navigate('/new-account/invite-team')}>Manage my team's schedule</Button>
                </div>
            </div>

        </motion.div>
    )

    return (
        <>
            <motion.div className='progress-bar' style={{ scaleX }} />

            <SplitWithImageRight
                image={SideImage}
                content={content}
            /></>
    );
};

export default NewAccount;
