import React, { useState, useEffect, useMemo } from 'react'
import {
    DocumentTextIcon,
    AdjustmentsHorizontalIcon
} from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ThinSidebar from '../../layouts/ThinSidebar'
import useFetch from '../../hooks/useFetch'
import convertNumberToPriority from '../../utils/formatters/NumberToPriority';
import secondsToDuration from '../../utils/formatters/SecondsToDuration';
import LoadingBanner from '../Notifications/Loading';
import StatusNotification from '../Notifications/StatusUpdate';
import AutoSizeTextInput from '../Inputs/AutoSizeTextInput';
import Button from '../Buttons/Button';
import ColorSelector from '../Dropdowns/ColorSelector';
import ProjectOverview from './Subviews/ProjectOverview';
import ProjectTimeline from './Subviews/ProjectTimeline';
import PrioritySelector from '../Dropdowns/PrioritySelector';
import ProgressSelector from '../Dropdowns/ProgressSelector';
import convertPriorityToNumber from '../../utils/formatters/ConvertPriority';
import Cookies from 'js-cookie';
import usePost from '../../hooks/usePost';
import convertDurationToSeconds from '../../utils/formatters/ConvertDuration';

const ProjectDetails = () => {

    const { id } = useParams();
    const additionalParams = useMemo(() => ({}), []);
    const { data: teamMembers, loading, error, refresh } = useFetch('/org/list-users', additionalParams)
    const { data: projectsData, loading: projectsLoading, error: projectsError, refresh: projectsRefresh } = useFetch('/org/list-projects', additionalParams)
    const [project, setProject] = useState(null);
    const [unchangedProject, setUnchangedProject] = useState(null); // Used to check if project has changed
    const [view, setView] = useState('overview');
    const [showNotification, setShowNotification] = useState(false);
    const [notificationBody, setNotificationBody] = useState(null);

    const [updateProject, , loadingUpdateProject, errorUpdatingProject] = usePost('/project/update');
    const [updateTask, , loadingUpdateTask, errorUpdatingTask] = usePost('/task/update');
    const [deleteTask, , loadingDeleteTask, errorDeletingTask] = usePost('/task/delete');
    const [scheduleTask, , loadingScheduleTask, errorSchedulingTask] = usePost('/task/schedule');

    const navigate = useNavigate();

    const saveDisabled = () => {
        if (project === unchangedProject) {
            return true;
        }

        if (project.timeNeeded === '') {
            return true;
        }

        for (let task of project.tasks) {
            if (task.name === '' || task.timeNeeded === '') {
                return true;
            }
        }

        return false;
    };

    const handleSave = async () => {
        try {
            // Tasks that were changed but the assignee stayed the same
            const changedTasks = project.tasks.filter((task, index) => {
                const unchangedTask = unchangedProject.tasks[index];
                return unchangedTask && task.id === unchangedTask.id && task.assignee === unchangedTask.assignee && JSON.stringify(task) !== JSON.stringify(unchangedTask);
            });

            // Tasks that were changed and the assignee changed
            const changedAssigneeTasks = project.tasks.filter((task, index) => {
                const unchangedTask = unchangedProject.tasks[index];
                return unchangedTask && task.id === unchangedTask.id && task.assignee !== unchangedTask.assignee && JSON.stringify(task) !== JSON.stringify(unchangedTask);
            });

            // Tasks that were deleted
            const deletedTasks = unchangedProject.tasks.filter(unchangedTask => !project.tasks.some(task => task.id === unchangedTask.id));

            // New tasks that were added
            const newTasks = project.tasks.filter(task => !unchangedProject.tasks.some(unchangedTask => unchangedTask.id === task.id));
            // Save the project
            const projectObj = {
                projectId: project.id,
                creatorId: project.creatorId,
                name: project.name,
                priority: convertPriorityToNumber(project.priority),
                status: project.status,
                timeNeeded: convertDurationToSeconds(project.timeNeeded),
                begin: project.start,
                end: project.deadline,
                orgId: Cookies.get('org'),
                color: project.color,
                description: project.description
            }

            const updateProjectResponse = await updateProject({ project: projectObj });
            // if there are changed tasks, save them
            for (const task of changedTasks) {
                const taskObj = {
                    projectId: project.id,
                    taskId: task.id,
                    name: task.name,
                    timeSpend: convertDurationToSeconds(task.timeNeeded),
                    begin: task.start,
                    end: task.deadline,
                    priority: convertPriorityToNumber(task.priority),
                    status: task.status,
                    orgId: Cookies.get('org'),
                    userId: task.assignee.userId,
                    timeblockMin: task.timeblockMin,
                    timeblockMax: task.timeblockMax,
                    color: task.color,
                }
                const updateTaskResponse = await updateTask({ task: taskObj });
            }
            // If there are changed assignee tasks, delete them and then schedule w new assignee
            for (const task of changedAssigneeTasks) {
                const deleteTaskResponse = await deleteTask({
                    taskId: task.id,
                    orgId: Cookies.get('org'),
                    userId: task.assignee.userId
                });

                const scheduleBody = {
                    task: {
                        name: task.name,
                        color: project.color,
                        begin: typeof task.start === 'object' ? task.start.format('YYYY-MM-DDTHH:mm:ssZ') : task.start,
                        end: typeof task.deadline === 'object' ? task.deadline.format('YYYY-MM-DDTHH:mm:ssZ') : task.deadline,
                        description: "",
                        orgId: task.orgId,
                        status: task.status,
                        timeSpend: convertDurationToSeconds(task.timeNeeded),
                        priority: convertPriorityToNumber(task.priority),
                        projectId: project.id,
                        timeblockMin: task.timeblockMin,
                        timeblockMax: task.timeblockMax,
                        orgId: Cookies.get('org'),
                        userId: task.assignee.userId
                    },

                }
                const scheduleTaskResponse = await scheduleTask(scheduleBody);
            }
            // If there are deleted tasks, delete them
            for (const task of deletedTasks) {
                const deleteTaskResponse = await deleteTask({
                    taskId: task.id,
                    orgId: Cookies.get('org'),
                    userId: task.assignee.userId
                });
            }
            // If there are new tasks, schedule them
            for (const task of newTasks) {
                const taskRequestBody = {
                    task: {
                        name: task.name,
                        color: project.color,
                        begin: typeof task.start === 'object' ? task.start.format('YYYY-MM-DDTHH:mm:ssZ') : task.start,
                        end: typeof task.deadline === 'object' ? task.deadline.format('YYYY-MM-DDTHH:mm:ssZ') : task.deadline,
                        description: "",
                        orgId: task.orgId,
                        status: task.status,
                        timeSpend: convertDurationToSeconds(task.timeNeeded),
                        priority: convertPriorityToNumber(task.priority),
                        projectId: project.id,
                        timeblockMin: task.timeblockMin,
                        timeblockMax: task.timeblockMax,
                        orgId: Cookies.get('org'),
                    },
                    
                }
                if (task.assignee && task.assignee.userId !== -1) {
                    taskRequestBody.userId = task.assignee.userId;
                }
                // If timeSpend is more than 4 hours, set timeblock min to 1 hr and max to 4 hrs

                if (taskRequestBody.task.timeSpend > 14400) {
                    taskRequestBody.task.timeblockMin = 3600
                    taskRequestBody.task.timeblockMax = 14400
                }
                const scheduleTaskResponse = await scheduleTask(taskRequestBody);
            }
            fullRefresh();
        } catch (error) {
            console.error(error);
            setShowNotification(true);
            setNotificationBody({
                title: "Error updating projects",
                // subtext: "Please try again",
                positive: false,
            })
        }
    };

    const fullRefresh = async () => {
        await new Promise(resolve => { projectsRefresh(); resolve(); });
    }

    useEffect(() => {
        if (projectsData) {
            const projectData = projectsData.projects.find(project => project.project.projectId === id);
            const project = projectData.project
            const tasks = projectData.tasks
            const formattedProject = {
                id: project.projectId,
                creatorId: project.creatorId,
                creatorName: projectsData.users.find(member => member.userId === project?.creatorId)?.name,
                name: project.name,
                status: project.status,
                priority: convertNumberToPriority(project.priority),
                timeNeeded: secondsToDuration(project.timeNeeded),
                start: project.begin,
                deadline: project.end,
                description: project.description,
                color: project.color,
                tasks: tasks.map(task => {
                    const assignee = projectsData.users.find(member => member.userId === task.userId);
                    return {
                        id: task.taskId,
                        name: task.name,
                        timeNeeded: secondsToDuration(Number(task.timeSpend)),
                        timeblockMin: Number(task.timeblockMin),
                        timeblockMax: Number(task.timeblockMax),
                        start: task.begin,
                        deadline: task.end,
                        priority: convertNumberToPriority(task.priority),
                        status: task.status,
                        orgId: task.orgId,
                        assignee: assignee ? { userId: assignee.userId, name: assignee.name } : undefined,
                        color: task.color,
                    }
                })
            };
            setProject(formattedProject);
            setUnchangedProject(formattedProject);
        }
    }, [projectsData, id]);

    const content = (
        <>
            <div className="py-5 lg:flex lg:items-start lg:justify-between">
                <div className="min-w-0 flex-1 justify-between border-b border-gray-200 flex flex-col lg:flex-row">
                    <div className='w-full'>
                        <div className='px-6 flex items-center'>
                            <ColorSelector
                                color={project?.color}
                                setColor={value => setProject({ ...project, color: value })}
                                className='h-7 w-7 rounded-full'
                            />
                            <div className='flex ml-2 mt-2 mr-2 items-center'>
                                <AutoSizeTextInput
                                    placeholder='Write a project name'
                                    value={project?.name}
                                    onChange={value => setProject({ ...project, name: value })}
                                    className='!text-2xl !font-bold !leading-7 text-gray-900 !cursor-pointer focus:!cursor-auto !ring-0 !shadow-none focus:!border-b-1 focus:!border-indigo-500 focus:!ring-1 !inline-block !w-auto'
                                />
                            </div>

                            <div>
                                <PrioritySelector
                                    priority={project?.priority}
                                    setPriority={value => setProject({ ...project, priority: value })}

                                />
                            </div>
                            <div>
                                <ProgressSelector
                                    progress={project?.status}
                                    setProgress={value => setProject({ ...project, status: value })}
                                    className='w-full'
                                />
                            </div>
                        </div>
                        <div className="px-6 ml-2.5 mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-2">
                            <div
                                className={`mt-2 flex items-center text-sm hover:bg-gray-100 p-2 cursor-pointer hover:border-b-2 ${view === 'overview' ? 'border-b-2 text-gray-700 font-medium' : 'text-gray-500'}`}
                                onClick={() => setView('overview')}
                            >
                                <DocumentTextIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                Overview
                            </div>
                            <div
                                className={`mt-2 flex items-center text-sm hover:bg-gray-100 p-2 cursor-pointer hover:border-b-2 ${view === 'timeline' ? 'border-b-2 text-gray-700 font-medium' : 'text-gray-500'}`}
                                onClick={() => setView('timeline')}
                            >
                                <AdjustmentsHorizontalIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                Timeline
                            </div>
                            {/* <div
                            className={`mt-2 flex items-center text-sm hover:bg-gray-100 p-2 cursor-pointer hover:border-b-2 ${view === 'board' ? 'border-b-2 text-gray-700 font-medium' : 'text-gray-500'}`}
                            onClick={() => setView('board')}
                        >
                            <PresentationChartBarIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            Board
                        </div> */}
                        </div>
                    </div>
                    <div className='flex items-start space-x-3 sm:mt-3.5 h-1/2 mr-6'>
                        <Button variant='neutral'
                            onClick={() => navigate("/projects")}
                        >
                            Back
                        </Button>
                        <Button
                            variant='additive'
                            disabled={saveDisabled()}
                            onClick={handleSave}
                        // loading={inviteTeamIsLoading || setPreferencesIsLoading}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
            <div className='px-6'>
                {view === 'overview' && ((project && teamMembers) ? <ProjectOverview project={project} setProject={setProject} teamMembers={teamMembers.users} /> : <></>)}
                {view === 'timeline' && <ProjectTimeline project={project} />}
                {view === 'board' && <div>Board content</div>}
            </div>
        </>

    )


    return (
        <>
            <div className='relative'>
                <ThinSidebar content={content} currentIdx={1} />
            </div>
            <LoadingBanner show={loading || projectsLoading || loadingUpdateProject || loadingUpdateTask || loadingScheduleTask || loadingDeleteTask} />
            <StatusNotification show={showNotification} setShow={setShowNotification} content={notificationBody} />

        </>
    )
}

export default ProjectDetails;