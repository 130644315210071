import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import Spinner from '../LoadingWheels/Spinner'
/**
 * content is {title: string, subtext: string? positive: bool }
 */
const LoadingBanner = ({ show, setShow }) => {

    return (
        <>
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    <Transition
                        show={show}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="translate-y-0 opacity-100 sm:translate-x-0"
                        leaveTo="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                    >
                        <div className="pointer-events-auto overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="p-4">
                                <div className="flex items-start">
                                    <Spinner />
                                   <p className='pl-3'>Loading...</p>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}

export default LoadingBanner