import React, { useState } from 'react';
import dayjs from 'dayjs'
import { Dialog } from '@headlessui/react'
import Cookies from 'js-cookie';
import usePost from '../../../hooks/usePost';
import convertDurationToSeconds from '../../../utils/formatters/ConvertDuration';
import convertPriorityToNumber from '../../../utils/formatters/ConvertPriority';
import Modal from './Modal';
import ConfigureProject from '../Views/ConfigureProject';
import PlanProject from '../Views/PlanProject';
import getRandomColor from '../../../utils/colors/RandomColor';
import '../../../utils/one-off-styles/antd.css'


const CreateProject = ({
    isVisible,
    setIsVisible,
    teamMembers,
    refreshProjects,
    setNotificationBody,
    setShowNotification }) => {
    const [project, setProject] = useState({
        name: '',
        startDate: dayjs(),
        dueDate: undefined,
        timeNeeded: '',
        priority: 'None',
        description: '',
        file: null
    });
    const [loading, setLoading] = useState(false);
    const [plannedProject, setPlannedProject] = useState(null)
    const [view, setView] = useState('configure');

    const [createProjectId, , projectIdIsLoading, projectIdError] = usePost('/project/create')
    const [createTask, , createTaskIsLoading, createTaskError] = usePost('/task/schedule')
    const [planProject, , planProjectIsLoading, planProjectError] = usePost('/beta/plan-project', null, process.env.REACT_APP_RENDER_BACKEND_URL);

    const handleSchedule = async () => {
        try {
            setLoading(true);
            const projectColor = getRandomColor();
            const projectIdResponse = await createProjectId({
                project: {
                    name: project.name,
                    begin: typeof project.startDate === 'object' ? project.startDate.format('YYYY-MM-DDTHH:mm:ssZ') : project.startDate,
                    end: typeof project.dueDate === 'object' ? project.dueDate.format('YYYY-MM-DDTHH:mm:ssZ') : project.dueDate,
                    timeNeeded: convertDurationToSeconds(project.timeNeeded),
                    priority: convertPriorityToNumber(project.priority),
                    status: "Not Started",
                    color: projectColor,
                    description: project.description,
                    orgId: Cookies.get("org")
                }
            })
            const projectId = projectIdResponse.data.project.projectId

            if (plannedProject.tasks.length === 0) {
                // Create a task for the entire project
                const taskRequestBody = {
                    task: {
                        "name": project.name,
                        "color": projectColor,
                        "begin": typeof project.startDate === 'object' ? project.startDate.format('YYYY-MM-DDTHH:mm:ssZ') : project.startDate,
                        "end": typeof project.dueDate === 'object' ? project.dueDate.format('YYYY-MM-DDTHH:mm:ssZ') : project.dueDate,
                        "description": project.description,
                        "orgId": Cookies.get("org"),
                        "status": "Not Started",
                        "timeSpend": convertDurationToSeconds(project.timeNeeded),
                        "priority": convertPriorityToNumber(project.priority),
                        "projectId": projectId,
                        "orgId": Cookies.get("org"),
                    },
                    
                };
                // If timeSpend is more than 4 hours, set timeblock min to 1 hr and max to 4 hrs
                if (taskRequestBody.task.timeSpend > 14400) {
                    taskRequestBody.task.timeblockMin = 3600
                    taskRequestBody.task.timeblockMax = 14400
                }
                const taskResponse = await createTask(taskRequestBody);
            } else {
                let previousTaskEnd = dayjs();

                for (const task of plannedProject.tasks) {
                    const taskRequestBody = {
                        task: {
                            "name": task.name,
                            "color": projectColor,
                            "begin": typeof previousTaskEnd === 'object' ? previousTaskEnd.format('YYYY-MM-DDTHH:mm:ssZ') : previousTaskEnd,
                            "end": typeof task.deadline === 'object' ? task.deadline.format('YYYY-MM-DDTHH:mm:ssZ') : task.deadline,
                            "description": "",
                            "orgId": Cookies.get("org"),
                            "status": task.status,
                            "timeSpend": convertDurationToSeconds(task.timeNeeded),
                            "priority": convertPriorityToNumber(task.priority),
                            "projectId": projectId,
                            "orgId": Cookies.get("org"),
                        },
                        
                    };
                    // If timeSpend is more than 4 hours, set timeblock min to 1 hr and max to 4 hrs
                    if (taskRequestBody.task.timeSpend > 14400) {
                        taskRequestBody.task.timeblockMin = 3600
                        taskRequestBody.task.timeblockMax = 14400
                    }

                    if (task.assignee && task.assignee.userId !== -1) {
                        taskRequestBody.userId = task.assignee.userId;
                    }
                    const taskResponse = await createTask(taskRequestBody);
                    previousTaskEnd = task.deadline;
                }
            }


            refreshProjects()
            setShowNotification(true);
            setNotificationBody({
                title: "Project created successfully 🎉",
                sutebext: "Focus time has been added to your team's calendar",
                positive: true,
            })
            setPlannedProject({
                id: 1,
                name: "",
                status: "Not Started",
                timeNeeded: "",
                deadline: "",
                tasks: []
            })
            setProject({
                name: '',
                startDate: dayjs(),
                dueDate: undefined,
                timeNeeded: '',
                priority: 'None',
                description: ''
            })
            setLoading(false);
            setIsVisible(false);
            setView('configure');
        } catch (error) {
            setShowNotification(true);
            setNotificationBody({
                title: "Error creating project",
                subtext: "Please try again",
                positive: false,
            })
        }
    };

    const goBack = () => {
        setView('configure');
    }

    const handleNext = async () => {
        try {
            const formData = new FormData();
            formData.append('projectBody', JSON.stringify({
                name: project.name,
                startDate: project.startDate.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                dueDate: project.dueDate.utc().format('YYYY-MM-DDTHH:mm:ssZ'),
                timeNeeded: project.timeNeeded,
                priority: project.priority,
                description: project.description,
            }));
            // Blank for now but eventually will have team members
            formData.append('teamData', JSON.stringify({}));
            if (project.file) {
                formData.append('pdf', project.file);
            }
            // Format the data properly so it can be sent to the configure-project endpoint
            const response = await planProject(formData)
            setPlannedProject(response.data)
            setView('planner');
        } catch (error) {
            console.error(error)
        }

    }

    const isDisabled = () => {
        return !project.name || !project.startDate || !project.dueDate || !project.timeNeeded;
    }

    return (
        <Modal open={isVisible} setOpen={setIsVisible}>
            <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl sm:p-5">
                {view === 'configure' ?
                    <ConfigureProject project={project} setProject={setProject} handleNext={handleNext} isLoading={planProjectIsLoading} isDisabled={isDisabled} setIsVisible={setIsVisible} />
                    :
                    <PlanProject project={plannedProject} setProject={setPlannedProject} handleSchedule={handleSchedule} isLoading={loading} isDisabled={isDisabled} goBack={goBack} showHeader={true} teamMembers={teamMembers} />}
            </Dialog.Panel>
        </Modal>
    );
};

export default CreateProject;

