import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import AutoSizeTextInput from '../components/Inputs/AutoSizeTextInput';
import CircleButton from '../components/Buttons/CircleButton';
import ApproveProjectTable from '../components/Tables/ApproveProjectTable';
import secondsToDuration from '../utils/formatters/SecondsToDuration';
import convertDurationToSeconds from '../utils/formatters/ConvertDuration';
import Cookies from 'js-cookie';
import Button from '../components/Buttons/Button';
import usePost from '../hooks/usePost';
import getRandomColor from '../utils/colors/RandomColor';
import dayjs from 'dayjs'
import convertPriorityToNumber from '../utils/formatters/ConvertPriority';
import StatusNotification from '../components/Notifications/StatusUpdate';
import LoadingBanner from '../components/Notifications/Loading';
import TaskApprovalContainer from '../components/Containers/TaskApprovalContainer';
import Logo from '../images/Logo.png'
import '../utils/one-off-styles/antd.css'

const temp_project = {
    name: 'Content Marketing Strategy',
    begin: '2024-02-20T09:00:00-06:00',
    end: '2024-05-20T18:00:00-06:00',
    timeNeeded: 259200,
    priority: 'High',
    status: 'Not Started',
    description: 'Creating and implementing a content marketing strategy to attract potential clients through insightful, relevant content.',
    tasks: [
        {
            id: 23243,
            name: 'Market Research',
            priority: 'High',
            status: 'Not Started',
            begin: '2024-02-20T09:00:00-06:00',
            deadline: '2024-02-27T18:00:00-06:00',
            description: 'Identify the interests and needs of the target market.',
            timeNeeded: 14400
        },
        {
            id: 232,
            name: 'Content Creation Plan',
            priority: 'High',
            status: 'Not Started',
            begin: '2024-02-28T09:00:00-06:00',
            deadline: '2024-03-15T18:00:00-06:00',
            description: 'Plan the types of content to be created, the channels for distribution, and the publication schedule.',
            timeNeeded: 28800
        },
        {
            id: 32,
            name: 'Content Creation',
            priority: 'High',
            status: 'Not Started',
            begin: '2024-03-16T09:00:00-06:00',
            deadline: '2024-05-15T18:00:00-06:00',
            description: 'Create blog posts, whitepapers, and case studies that provide value to the target market.',
            timeNeeded: 115200
        },
        {
            id: 7,
            name: 'Content Distribution',
            priority: 'High',
            status: 'Not Started',
            begin: '2024-03-16T09:00:00-06:00',
            deadline: '2024-05-20T18:00:00-06:00',
            description: 'Publish and promote the created content through selected channels.',
            timeNeeded: 57600
        }
    ]
}


const ApproveProject = ({ }) => {
    const [project, setProject] = useState(null);
    const location = useLocation(); // Use useLocation to access the query string
    const [loading, setLoading] = useState(false);
    const [showNotification, setShowNotification] = useState(false)
    const [notificationBody, setNotificationBody] = useState(null)
    const [doneCreating, setDoneCreating] = useState(false)

    const [createProjectId, , projectIdIsLoading, projectIdError] = usePost('/project/create')
    const [createTask, , createTaskIsLoading, createTaskError] = usePost('/task/schedule')

    // Function to parse query parameters
    const useQuery = () => {
        return new URLSearchParams(location.search);
    }

    const query = useQuery();
    const id = query.get('id');
    const token = query.get('token');
    const orgId = query.get('orgId')
    Cookies.set('org', orgId)
    Cookies.set('token', token)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_RENDER_BACKEND_URL}/beta/retrieve-draft-project?id=${id}`, {
                    headers: {
                        token: token
                    }
                });
                const _project = response.data
                _project.timeNeeded = secondsToDuration(_project.timeNeeded)
                _project.tasks.forEach(task => {
                    task.timeNeeded = secondsToDuration(task.timeNeeded)
                });
                setProject(_project)
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };

        if (id && token) fetchData()
    }, [id, token]);

    const editTask = (taskId, updatedTask) => {
        const updatedTasks = project.tasks.map(task => {
            if (task.id === taskId) {
                return { ...task, ...updatedTask };
            }
            return task;
        });

        setProject({ ...project, tasks: updatedTasks });
    };

    const deleteTask = (taskId) => {
        const updatedTasks = project.tasks.filter(task => task.id !== taskId);
        setProject({ ...project, tasks: updatedTasks });
    };

    const addTask = () => {
        const newTask = {
            id: uuidv4(),
            name: '',
            priority: "Medium",
            status: 'Not Started',
            begin: '',
            deadline: '',
            description: '',
            timeNeeded: ''
        };
        const updatedTasks = [...project.tasks, newTask];
        setProject({ ...project, tasks: updatedTasks });
    }

    const handleSchedule = async () => {
        try {
            setLoading(true);
            const projectColor = getRandomColor();
            const projectIdResponse = await createProjectId({
                project: {
                    name: project.name,
                    begin: typeof project.begin === 'object' ? project.begin.format('YYYY-MM-DDTHH:MM:ssZ') : project.begin,
                    end: typeof project.end === 'object' ? project.end.format('YYYY-MM-DDTHH:MM:ssZ') : project.end,
                    timeNeeded: convertDurationToSeconds(project.timeNeeded),
                    priority: convertPriorityToNumber(project.priority),
                    status: "Not Started",
                    color: projectColor,
                    description: project.description,
                    orgId: Cookies.get("org")
                }
            })
            const projectId = projectIdResponse.data.project.projectId

            if (project.tasks.length === 0) {
                // Create a task for the entire project
                const taskRequestBody = {
                    task: {
                        "name": project.name,
                        "color": projectColor,
                        "begin": typeof project.startDate === 'object' ? project.startDate.format('YYYY-MM-DDTHH:mm:ssZ') : project.startDate,
                        "end": typeof project.dueDate === 'object' ? project.dueDate.format('YYYY-MM-DDTHH:mm:ssZ') : project.dueDate,
                        "description": project.description,
                        "orgId": Cookies.get("org"),
                        "status": "Not Started",
                        "timeSpend": convertDurationToSeconds(project.timeNeeded),
                        "priority": convertPriorityToNumber(project.priority),
                        "projectId": projectId,
                        "orgId": Cookies.get("org"),
                    },

                };
                // If timeSpend is more than 4 hours, set timeblock min to 1 hr and max to 4 hrs
                if (taskRequestBody.task.timeSpend > 14400) {
                    taskRequestBody.task.timeblockMin = 3600
                    taskRequestBody.task.timeblockMax = 14400
                }
                const taskResponse = await createTask(taskRequestBody);
            } else {
                let previousTaskEnd = dayjs();

                for (const task of project.tasks) {
                    const taskRequestBody = {
                        task: {
                            "name": task.name,
                            "color": projectColor,
                            "begin": typeof previousTaskEnd === 'object' ? previousTaskEnd.format('YYYY-MM-DDTHH:mm:ssZ') : previousTaskEnd,
                            "end": typeof task.deadline === 'object' ? task.deadline.format('YYYY-MM-DDTHH:mm:ssZ') : task.deadline,
                            "description": "",
                            "orgId": Cookies.get("org"),
                            "status": task.status,
                            "timeSpend": convertDurationToSeconds(task.timeNeeded),
                            "priority": convertPriorityToNumber(task.priority),
                            "projectId": projectId,
                            "orgId": Cookies.get("org"),
                        },

                    };
                    // If timeSpend is more than 4 hours, set timeblock min to 1 hr and max to 4 hrs
                    if (taskRequestBody.task.timeSpend > 14400) {
                        taskRequestBody.task.timeblockMin = 3600
                        taskRequestBody.task.timeblockMax = 14400
                    }

                    if (task.assignee && task.assignee.userId !== -1) {
                        taskRequestBody.userId = task.assignee.userId;
                    }
                    const taskResponse = await createTask(taskRequestBody);
                    previousTaskEnd = task.deadline;
                }
            }



            setShowNotification(true);
            setNotificationBody({
                title: "Project created successfully 🎉",
                sutebext: "Focus time has been added to your team's calendar",
                positive: true,
            })
            setDoneCreating(true)
            setLoading(false);

        } catch (error) {
            setShowNotification(true);
            setNotificationBody({
                title: "Error creating project",
                subtext: "Please try again",
                positive: false,
            })
            console.error(error)
        }
    };

    return (
        <>
            {/* Mobile View */}
            <div className="md:hidden flex flex-col px-2" style={{ height: 'calc(100vh - 70px)' }}> {/* Adjust 20px if you have known headers/footers */}
                <div className='flex flex-col items-center mb-2 mt-3'>
                    <img src={Logo} alt='Logo' className='mb-2 w-8 h-8' />
                </div>
                <div className="px-4 flex justify-start"> {/* Top part with minimal padding */}
                    <AutoSizeTextInput
                        placeholder='Write a project name'
                        value={project?.name}
                        onChange={value => setProject({ ...project, name: value })}
                        className='text-2xl font-bold leading-7 text-gray-900 cursor-pointer focus:cursor-auto ring-0 shadow-none focus:border-b-1 focus:border-indigo-500 focus:ring-1 inline-block w-auto'
                    />
                </div>
                <div className='px-5 flex items-center justify-begin mt-2'>
                    <p className='font-semibold mr-2'>Tasks</p>
                    <CircleButton onClick={() => addTask()}> + </CircleButton>
                </div>
                <hr className="px-4 mt-2 w-full border-t" />
                <div className='mt-1 py-3 px-3 space-y-1.5 overflow-y-auto ' style={{ maxHeight: '700px' }}>
                    {project?.tasks?.map(task => {
                        return <TaskApprovalContainer task={task} editTask={editTask} deleteTask={deleteTask} />
                    })}
                </div>
                <div className="mt-auto pb-4 px-4 flex justify-center"> {/* Bottom part with minimal padding */}
                    <Button variant="additive" disabled={doneCreating} loading={loading} onClick={() => handleSchedule()}>Schedule ✨</Button>
                </div>
                {showNotification && <StatusNotification show={showNotification} setShow={setShowNotification} content={notificationBody} />

                }
            </div>

            {/* Desktop View */}
            <div className="hidden md:block">
                <div className="flex justify-center items-center h-screen">
                    <div className="w-full max-w-4xl mx-auto p-4 shadow-lg border rounded-lg mx-2">
                        <div className='flex items-center justify-begin'>
                            <div className='flex ml-2 mt-2 mr-2 items-center w-5/6'>
                                <AutoSizeTextInput
                                    placeholder='Write a project name'
                                    value={project?.name}
                                    onChange={value => setProject({ ...project, name: value })}
                                    className='!text-2xl !font-bold !leading-7 text-gray-900 !cursor-pointer focus:!cursor-auto !ring-0 !shadow-none focus:!border-b-1 focus:!border-indigo-500 focus:!ring-1 !inline-block !w-auto'
                                />
                            </div>
                            <div className='w-1/3'>
                                <Button variant="additive" disabled={doneCreating} loading={loading} onClick={() => handleSchedule()}>Schedule ✨</Button>
                            </div>
                        </div>

                        <div className='flex items-center justify-begin mt-2'>
                            <p className='ml-3 font-semibold mr-2'>Tasks</p>
                            <CircleButton onClick={() => addTask()}> + </CircleButton>
                        </div>
                        {project?.tasks.length !== 0 && (
                            <ApproveProjectTable tasks={project?.tasks} editTask={editTask} deleteTask={deleteTask} />
                        )}
                    </div>
                </div>
                <StatusNotification show={showNotification} setShow={setShowNotification} content={notificationBody} />
                <LoadingBanner show={loading} />
            </div>
        </>
    );
};

export default ApproveProject