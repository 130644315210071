import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Spinner from '../LoadingWheels/Spinner';

const CircleButton = ({ children, onClick, disabled, loading, className, ...rest }) => {

  const disabledStyle = disabled || loading ? 'opacity-50 cursor-not-allowed' : '';

  return (
    <motion.button
      whileHover={{ scale: disabled ? 1.0 : 1.05 }}
      whileTap={{ scale: disabled ? 1.0 : 0.9 }}
      onClick={disabled || loading ? null : onClick}
      disabled={disabled || loading}
      className={`flex items-center justify-center p-2 rounded-full shadow-sm font-base text-sm  focus:outline-none  bg-main-purple text-white hover:bg-purple-800 w-6 h-6 ${disabledStyle}`}
      {...rest}
    >
      {loading ? (
        <div className="flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        children
      )}
    </motion.button>
  );
};

export default CircleButton;
