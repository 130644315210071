import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TextInput = styled.input`
  padding: 0 5px; /* Add 5px of padding to the left and right */
  margin: 0;
  border: none;
  border-radius: 1;
  font-family: inherit;
  font-size: inherit;
  position: absolute;
  vertical-align: top;
  top: 0;
  left: 0;
  width: calc(100% + 10px); /* Subtract the padding from the width */
  background: white;
`;

const Label = styled.label`
  display: inline-block;
  position: relative;
  min-width: 2em;
  min-height: 1.4em;
`;

const Template = styled.span`
  white-space: pre;
`;

const AutoSizeTextInput = ({ value, onChange, ...rest }) => {
    const [content, setContent] = useState(value);

    useEffect(() => {
        setContent(value);
    }, [value]);

    const handleChange = (e) => {
        setContent(e.target.value);
        onChange(e.target.value);
    };

    return (
        <Label {...rest}>
            <Template>{content}</Template>
            <TextInput type="text" value={content} onChange={handleChange} />
        </Label>
    );
};

export default AutoSizeTextInput;