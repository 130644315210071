import React, { useState, useEffect, useMemo } from 'react'
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import useFetch from '../../hooks/useFetch';
import usePost from '../../hooks/usePost';
import ThinSidebar from '../../layouts/ThinSidebar'
import Button from '../../components/Buttons/Button'
import ProjectTable from '../../components/Tables/ProjectTable'
import CreateProject from '../../components/Modals/Modals/CreateProject'
import secondsToDuration from '../../utils/formatters/SecondsToDuration';
import convertNumberToPriority from '../../utils/formatters/NumberToPriority';
import convertPriorityToNumber from '../../utils/formatters/ConvertPriority';
import LoadingBanner from '../../components/Notifications/Loading';
import convertDurationToSeconds from '../../utils/formatters/ConvertDuration';
import StatusNotification from '../../components/Notifications/StatusUpdate';

const formatTeam = (teamMembers) => {
    try {
        return teamMembers?.users.map(member => ({
            name: member.name,
            userId: member.userId
        }))
    } catch (error) {
        console.error(error)
        return []
    }
}

const formatProjects = (projectsData) => {
    try {
        return projectsData.projects.map(_project => {
            const project = _project.project
            return {
                id: project.projectId,
                creatorId: project.creatorId,
                creatorName: projectsData.users.find(member => member.userId === project.creatorId).name,
                name: project.name,
                status: project.status,
                priority: convertNumberToPriority(project.priority),
                timeNeeded: secondsToDuration(project.timeNeeded),
                description: project.description,
                start: project.begin,
                deadline: project.end,
                color: project.color,
                tasks: _project.tasks.map(task => {
                    const assignee = projectsData.users.find(member => member.userId === task.userId);
                    return {
                        id: task.taskId,
                        name: task.name,
                        timeNeeded: secondsToDuration(Number(task.timeSpend)),
                        timeblockMin: Number(task.timeblockMin),
                        timeblockMax: Number(task.timeblockMax),
                        start: task.begin,
                        deadline: task.end,
                        priority: convertNumberToPriority(task.priority),
                        status: task.status,
                        orgId: task.orgId,
                        assignee: assignee ? { userId: assignee.userId, name: assignee.name } : undefined,
                        color: project.color,
                    };
                })
            }
        })
    } catch (error) {
        console.error(error);
        return []
    }
};

const ListProjects = () => {
    const [createProjectOpen, setCreateProjectOpen] = useState(false)
    const [projects, setProjects] = useState([])
    const additionalParams = useMemo(() => ({}), []);
    const { data: teamMembers, loading, error, refresh } = useFetch('/org/list-users', additionalParams)
    const { data: projectsData, loading: projectsLoading, error: projectsError, refresh: projectsRefresh } = useFetch('/org/list-projects', additionalParams)
    const location = useLocation();
    const [originalProjects, setOriginalProjects] = useState([]);
    const [changedProjects, setChangedProjects] = useState([]);
    const [changedTasks, setChangedTasks] = useState([]);
    const [addedTasks, setAddedTasks] = useState([]);
    const [deletedTasks, setDeletedTasks] = useState([]);
    const [updateProject, , loadingUpdateProject, errorUpdatingProject] = usePost('/project/update');
    const [updateTask, , loadingUpdateTask, errorUpdatingTask] = usePost('/task/update');
    const [deleteTask, , loadingDeleteTask, errorDeletingTask] = usePost('/task/delete');
    const [scheduleTask, , loadingScheduleTask, errorSchedulingTask] = usePost('/task/schedule');

    const [showNotification, setShowNotification] = useState(false)
    const [notificationBody, setNotificationBody] = useState(null)

    useEffect(() => {
        if (projectsData) {
            const formattedProjects = formatProjects(projectsData);
            console.log(formattedProjects);
            setProjects(formattedProjects);
        }
    }, [projectsData]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const open = params.get('open');
        if (open) {
            setCreateProjectOpen(true)
        }
    }, [location]);


    const showSaveButton = useMemo(() => {
        return changedProjects.length > 0 || changedTasks.length > 0 || addedTasks.length > 0 || deletedTasks.length > 0;
    });

    const handleSave = async () => {
        try {
            const uniqueChangedProjects = changedProjects.filter((project, index, self) =>
                index === self.findIndex((p) => (
                    p === project
                ))
            );
            const _uniqueChangedTasks = changedTasks.filter((task, index, self) =>
                index === self.findIndex((t) => (
                    t === task
                ))
            );

            // update uniqueChangedTasks filter out any id that is in addedTasks or deletedTasks
            const uniqueChangedTasks = _uniqueChangedTasks.filter(task => {
                const addedTaskIds = addedTasks.map(task => task._taskId);
                const deletedTaskIds = deletedTasks.map(task => task.taskId);
                return !addedTaskIds.includes(task) && !deletedTaskIds.includes(task);
            });

            // Create the array of deletedTaskIds once, outside the filter function
            const deletedTaskIds = deletedTasks.map(task => task.taskId);

            // Filter addedTasks to only include tasks that are not in deletedTasks
            const uniqueAddedTasks = addedTasks.filter(task => !deletedTaskIds.includes(task._taskId));


            for (const project of uniqueChangedProjects) {
                const _projectObj = projects.find(p => p.id === project);
                // Create the project object
                const projectObj = {
                    projectId: _projectObj.id,
                    creatorId: _projectObj.creatorId,
                    name: _projectObj.name,
                    priority: convertPriorityToNumber(_projectObj.priority),
                    status: _projectObj.status,
                    timeNeeded: convertDurationToSeconds(_projectObj.timeNeeded),
                    begin: _projectObj.start,
                    end: _projectObj.deadline,
                    orgId: Cookies.get("org"),
                    color: _projectObj.color,
                    description: _projectObj.description
                }



                // Update the project
                // TODO: api call
                const updateProjectResponse = await updateProject({ project: projectObj });

                // Get the tasks for the project
                const projectTasks = [..._projectObj.tasks];
                const tasksWithChangedAssignee = projectTasks.filter(task =>
                    uniqueChangedTasks.includes(task.id) &&
                    !uniqueAddedTasks.some(addedTask => addedTask._taskId === task.id)
                );

                const tasksWithoutChangedAssignee = projectTasks.filter(task =>
                    !uniqueChangedTasks.includes(task.id) &&
                    !uniqueAddedTasks.some(addedTask => addedTask._taskId === task.id)
                );

                // Loop through tasks without changed assignee
                for (const task of tasksWithoutChangedAssignee) {
                    const taskObj = {
                        projectId: _projectObj.id,
                        taskId: task.id,
                        name: task.name,
                        timeSpend: convertDurationToSeconds(task.timeNeeded),
                        begin: task.start,
                        end: task.deadline,
                        priority: convertPriorityToNumber(task.priority),
                        status: task.status,
                        orgId: Cookies.get("org"),
                        userId: task.assignee.userId,
                        timeblockMin: task.timeblockMin,
                        timeblockMax: task.timeblockMax,
                        color: task.color,

                    }
                    // Update the task
                    const updateTaskResponse = await updateTask({ task: taskObj });


                }

                // Loop through tasks with changed assignee
                //  `for each task, delete it, then schedule it
                for (const task of tasksWithChangedAssignee) {
                    const originalTask = originalProjects
                        .find(p => p.id === _projectObj.id)
                        .tasks.find(t => t.id === task.id);

                    const deleteBody = {
                        "taskId": task.id,
                        orgId: Cookies.get("org"),
                        userId: originalTask.assignee.userId
                    }

                    // Delete the task
                    // TODO: api call
                    const deleteResponse = await deleteTask(deleteBody);


                    // schedule new task with new assignee
                    const scheduleBody = {
                        task: {
                            "name": task.name,
                            "color": _projectObj.color,
                            "begin": typeof task.start === 'object' ? task.start.format('YYYY-MM-DDTHH:mm:ssZ') : task.start,
                            "end": typeof task.deadline === 'object' ? task.deadline.format('YYYY-MM-DDTHH:mm:ssZ') : task.deadline,
                            "description": "",
                            "orgId": task.orgId,
                            "status": task.status,
                            "timeSpend": convertDurationToSeconds(task.timeNeeded),
                            "priority": convertPriorityToNumber(task.priority),
                            "projectId": _projectObj.id,
                            "timeblockMin": task.timeblockMin,
                            "timeblockMax": task.timeblockMax,
                            "orgId": Cookies.get("org"),
                            "userId": task.assignee.userId
                        },

                    }



                    // Schedule the task
                    // TODO: api call
                    const scheduleResponse = await scheduleTask(scheduleBody);
                }
            }

            // Handle add tasks
            try {
                for (const _task of uniqueAddedTasks) {
                    const project = projects.find(p => p.id === _task.projectId);
                    const task = project.tasks.find(t => t.id === _task._taskId);

                    const taskRequestBody = {
                        task: {
                            name: task.name,
                            color: project.color,
                            begin: typeof task.start === 'object' ? task.start.format('YYYY-MM-DDTHH:mm:ssZ') : task.start,
                            end: typeof task.deadline === 'object' ? task.deadline.format('YYYY-MM-DDTHH:mm:ssZ') : task.deadline,
                            description: "",
                            orgId: Cookies.get("org"),
                            status: task.status,
                            timeSpend: convertDurationToSeconds(task.timeNeeded),
                            priority: convertPriorityToNumber(task.priority),
                            projectId: project.id,
                            orgId: Cookies.get("org"),

                        },
                        
                    }
                    if (task.assignee && task.assignee.userId !== -1) {
                        taskRequestBody.userId = task.assignee.userId;
                    }
                    // If timeSpend is more than 4 hours, set timeblock min to 1 hr and max to 4 hrs

                    if (taskRequestBody.task.timeSpend > 14400) {
                        taskRequestBody.task.timeblockMin = 3600
                        taskRequestBody.task.timeblockMax = 14400
                    }
                    const taskResponse = await scheduleTask(taskRequestBody);
                }
            } catch (error) {
                console.error(error);
                setShowNotification(true);
                setNotificationBody({
                    title: "Error adding tasks",
                    subtext: "Please make sure task has all required fields",
                    positive: false,
                })
                throw error;
            }

            // Handle delete tasks
            for (const task of deletedTasks) {
                const _projectObj = projects.find(p => p.id === task.projectId);
                const originalTask = originalProjects
                    .find(p => p.id === _projectObj.id)
                    .tasks.find(t => t.id === task.taskId);
                if (originalTask) {
                    const deleteBody = {
                        taskId: task.taskId,
                        orgId: Cookies.get("org"),
                        userId: originalTask.assignee.userId
                    }

                    // Delete the task
                    const deleteResponse = await deleteTask(deleteBody);
                }

            }
            projectsRefresh();
            setChangedProjects([]);
            setChangedTasks([]);
            setAddedTasks([]);
            setDeletedTasks([]);
            setOriginalProjects([...projects]);
            setShowNotification(true);
            setNotificationBody({
                title: "Projects updated successfully",
                // subtext: "Please try again",
                positive: true,
            })
        } catch (error) {
            console.error(error);
            setShowNotification(true);
            setNotificationBody({
                title: "Error updating projects",
                // subtext: "Please try again",
                positive: false,
            })
        }
    }

    const content = (
        <div className='flex flex-col justify-between p-8'>
            <div className='flex flex-row justify-between items-center mb-5 '>
                <div className='flex-grow'>
                    <h1 className='text-2xl font-extrabold text-gray-900'>
                        Projects
                    </h1>
                    <p className='text-sm mt-3 text-gray-600'>
                        All your projects are listed below.
                    </p>
                </div>
                <div className={`flex-shrink-0 ml-4 space-x-3 flex flex-row ${showSaveButton ? 'w-72' : 'w-auto'}`}>
                    {showSaveButton && (
                        <Button variant='additive' onClick={handleSave}>Save Changes</Button>
                    )}
                    <Button variant={showSaveButton ? 'neutral' : 'additive'} disabled={showSaveButton} onClick={() => setCreateProjectOpen(true)}>+ New Project</Button>
                </div>
            </div>


            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.75 }}
                className='flex justify-center'
            >
                {projects.length > 0 && (
                    <ProjectTable
                        projects={projects}
                        setProjects={setProjects}
                        setChangedProjects={setChangedProjects}
                        setChangedTasks={setChangedTasks}
                        setAddedTasks={setAddedTasks}
                        setDeletedTasks={setDeletedTasks}
                        teamMembers={formatTeam(teamMembers)}
                    />
                )}
            </motion.div>

        </div>
    )

    return (
        <>
            <div className='relative'>
                <CreateProject
                    isVisible={createProjectOpen}
                    setIsVisible={setCreateProjectOpen}
                    teamMembers={formatTeam(teamMembers)}
                    refreshProjects={projectsRefresh}
                    setNotificationBody={setNotificationBody}
                    setShowNotification={setShowNotification}
                />
                <ThinSidebar content={content} currentIdx={1} />
            </div>
            <LoadingBanner show={loading || projectsLoading || loadingUpdateProject || loadingUpdateTask || loadingDeleteTask || loadingScheduleTask} />
            <StatusNotification show={showNotification} setShow={setShowNotification} content={notificationBody} />
        </>
    )
}

export default ListProjects