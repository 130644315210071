import React from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/20/solid'

const TextInput = ({
  label, // The text for the label
  id = 'text-input', // Default ID if not provided
  type = 'text', // Default input type
  placeholder = '', // Placeholder text
  value = '',
  invalid = false, // If true, show validation error
  errorMessage = '', // Validation error message
  onChange, // Function to handle input change
  className = '', // Additional classnames
  onBlur = null,
  ...rest // Other props
}) => {
  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className={`block text-sm font-medium leading-6 text-gray-900`}
        >
          {label}
        </label>
      )}
      <div
        className={`relative mt-2 rounded-md shadow-sm ${invalid ? 'ring-1 ring-inset ring-red-300' : ''
          } ${className}`}
      >
        <input
          type={type}
          name={id}
          id={id}
          className={`block w-full rounded-md border-0 py-1.5 pr-10 sm:text-sm sm:leading-6 ${invalid
            ? 'text-red-900 placeholder:text-red-300 focus:ring-red-500'
            : ''
            } focus:ring-2 focus:ring-inset focus:ring-indigo-500 ring-1 ring-inset ring-gray-300 ${className}`}
          placeholder={placeholder}
          value={value}
          aria-invalid={invalid}
          aria-describedby={invalid ? `${id}-error` : undefined}
          onChange={onChange}
          onBlur={onBlur && onBlur}
          {...rest}
        />
        {invalid && (
          <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
            <ExclamationCircleIcon
              className='h-5 w-5 text-red-500'
              aria-hidden='true'
            />
          </div>
        )}
      </div>
      {invalid && (
        <p className={`mt-2 text-sm text-red-600 ${className}`} id={`${id}-error`}>
          {errorMessage}
        </p>
      )}
    </div>
  )
}

export default TextInput
