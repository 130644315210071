import React from "react";
import dayjs from 'dayjs'
import { DatePicker } from "antd";
import { v4 as uuidv4 } from 'uuid'
import ProjectPlannerTable from "../../Tables/ProjectPlannerTable";
import DurationInput from "../../Inputs/DurationInput";
import CircleButton from "../../Buttons/CircleButton";
import PrioritySelector from "../../Dropdowns/PrioritySelector";

const ProjectOverview = ({ project, setProject, teamMembers }) => {
    const showDelete = true;
    const addTask = () => {
        const newTask = {
            id: uuidv4(),
            name: '',
            assignee: undefined,
            timeNeeded: '',
            start: dayjs(),
            deadline: dayjs().add(1, 'day'),
            priority: 'None',
            status: 'Not Started'
        };
        // setProject({ ...project, tasks: [...project.tasks, newTask] }, "add", { _taskId: newTask.id, projectId: project.id });
        setProject({ ...project, tasks: [...project.tasks, newTask] });
    }

    const deleteTask = id => {
        const updatedTasks = project.tasks.filter(task => task.id !== id);
        // setProject({ ...project, tasks: updatedTasks }, "delete", { taskId: id, projectId: project.id });
        setProject({ ...project, tasks: updatedTasks });
    }

    const editTask = (id, updatedTask) => {
        const updatedTasks = project.tasks.map(task => task.id === id ? updatedTask : task);
        setProject({ ...project, tasks: updatedTasks });
    }
    return (
        <div>
            <div className="flex items-center justify-start">
                <div className="flex flex-col w-1/5">
                    <label htmlFor="start-date" className="text-md font-medium text-gray-900 mb-2">
                        Start Date
                    </label>
                    <DatePicker
                        id="start-date"
                        use12Hours
                        changeOnBlur
                        minuteStep={5}
                        popupClassName="noFooterTimePick"
                        showTime={{
                            format: "h:mm a",
                        }}
                        value={project?.start ? dayjs(project.start) : undefined}
                        format="MM/DD/YYYY h:mm a"
                        allowClear={false}
                        onChange={(value) => {
                            setProject({ ...project, startDate: value });
                        }}
                        className="h-8"
                    />
                </div>
                <div className="ml-5 flex flex-col w-1/5">
                    <label htmlFor="start-date" className="text-md font-medium text-gray-900 mb-2">
                        Deadline
                    </label>
                    <DatePicker
                        id="start-date"
                        use12Hours
                        changeOnBlur
                        minuteStep={5}
                        popupClassName="noFooterTimePick"
                        showTime={{
                            format: "h:mm a",
                        }}
                        value={project?.deadline ? dayjs(project.deadline) : undefined}
                        format="MM/DD/YYYY h:mm a"
                        allowClear={false}
                        onChange={(value) => {
                            setProject({ ...project, deadline: value });
                        }
                        }
                        className="h-8"
                    />
                </div>
                <div className="ml-5 flex flex-col w-1/5">
                    <label htmlFor="time-needed" className="text-md font-medium text-gray-900">
                        Time Needed
                    </label>
                    <DurationInput
                        className="w-56 h-8"
                        placeholder="1 hr 30 min"
                        value={project?.timeNeeded}
                        onChange={(value) => setProject({ ...project, timeNeeded: value })}
                    />
                </div>
                {/* <div className="ml-5 flex flex-col w-1/5">
                <label htmlFor="priority" className="text-sm font-medium text-gray-900">
                    Priority
                </label>
                <PrioritySelector
                    className="w-56 h-8"
                    priority={project.priority}
                    setPriority={value => setProject({ ...project, priority: value })}
                />

            </div> */}
            </div>
            <div className='mt-5'>
                <label className="text-md font-medium text-gray-900">Project description</label>
                <textarea
                    rows={5}
                    name="preferences"
                    id="preferences"
                    className="mt-2 block w-full  resize-none border border-gray-200 p-2  rounded-lg text-gray-900 placeholder:text-gray-400 focus:border-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    placeholder="What's this project about?"
                    value={project.description}
                    onChange={(e) => setProject({ ...project, description: e.target.value })}
                />
            </div>
            <div className="mt-5">
                <div className="flex w-1/12 items-center mb-1">
                    <h3 className={`text-gray-900 text-md font-medium my-auto mr-2`}>Tasks</h3>
                    <CircleButton className="my-auto" onClick={() => addTask()}>+</CircleButton>
                </div>
                <ProjectPlannerTable tasks={project.tasks} editTask={editTask} showDelete={showDelete} deleteTask={deleteTask} teamMembers={teamMembers} />

            </div>

        </div>

    )
}

export default ProjectOverview
