import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
    baseURL: process.env.REACT_APP_AWS_BACKEND_URL,
});

api.interceptors.request.use((config) => {
    const token = Cookies.get('token');
    config.headers.token = token ? `${token}` : '';
    return config;
});

export default api;
