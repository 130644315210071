import React from 'react'
import { Route, Routes } from 'react-router-dom';
import ProjectDetails from '../components/ProjectDisplay/ProjectDetails';
import ListProjects from '../components/ProjectDisplay/ListProjects';

const Projects = () => {

  return (
    <Routes>
      <Route index element={<ListProjects />} />
      <Route path=":id" element={<ProjectDetails />} />
    </Routes>
  )
}

export default Projects
