const convertDurationToSeconds = (duration) => {
    const parts = duration.split(' ').filter((_, i) => i % 2 === 0);
    let hours = 0;
    let minutes = 0;

    if (parts.length === 1 && duration.includes('min')) {
        minutes = Number(parts[0]);
    } else {
        [hours, minutes] = parts.map(Number);
    }

    return (hours || 0) * 3600 + (minutes || 0) * 60;
};

export default convertDurationToSeconds;