import React, { useState } from 'react'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { motion, useSpring } from 'framer-motion'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import SplitWithImageRight from '../layouts/SplitWithImageRight'
import Button from '../components/Buttons/Button'
import TextInput from '../components/Inputs/TextInput'
import InviteTeamImage from '../images/InviteTeamImage.png'
import Logo from '../images/Logo.png'
import usePost from '../hooks/usePost'

import '../utils/one-off-styles/motion.css'
const InviteTeam = () => {
  const [teamName, setTeamName] = useState('')
  const [teamMembers, setTeamMembers] = useState(['', ''])
  // const [createOrg, response, isLoading, error] = usePost('/org/create');
  const [setOrgName, , setOrgNameIsLoading, setOrgNameError] = usePost('/org/update');
  const [inviteTeam, response, isLoading, error] = usePost('/beta/invite-team', null, process.env.REACT_APP_RENDER_BACKEND_URL);

  const navigate = useNavigate();

  const scaleX = useSpring(0.25, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })

  const handleTeamNameChange = e => {
    setTeamName(e.target.value)
  }

  const handleMemberChange = (index, value) => {
    setTeamMembers(members => {
      const newMembers = [...members]
      newMembers[index] = value
      return newMembers
    })
  }

  const removeMember = index => {
    setTeamMembers(members => {
      const newMembers = [...members]
      newMembers.splice(index, 1)
      return newMembers
    })
  }

  const addMoreEmailInputs = () => {
    setTeamMembers(members => [...members, ''])
  }

  const isDisabled = () => {
    return !teamName || !teamMembers.some(member => member)
  }

  const handleSubmit = async () => {
    try {
      // Set the org name
      // const orgNameResponse = await setOrgName({
      //   org: {
      //     name: teamName,
      //     orgId: Cookies.get('org'),
      //   }
      // });
      // Filter out any emails that are empty strings
      const filteredTeamMembers = teamMembers.filter(member => member);
      const response = await inviteTeam({
        teamName: teamName,
        emails: filteredTeamMembers,
        orgId: Cookies.get('org'),
      });
      navigate('/new-account/working-hours');
    } catch (error) {
      console.error(error);
    }
  };

  const content = (
    <div className='flex flex-col justify-between'>
      <div className='space-y-6 mb-5'>
        <img src={Logo} alt='Apollo Logo' className='mx-auto h-12 w-auto' />
        <h1 className='text-center text-4xl font-extrabold text-gray-900'>
          Setup your team
        </h1>
        <p className='text-center text-sm text-gray-600'>
          Invite your teammates to get the most out of Apollo.
        </p>
        <div className='space-y-4'>
          <div className='font-medium leading-6 text-gray-900'>Team name</div>
          <TextInput
            id='team-name'
            placeholder='Team name'
            value={teamName}
            onChange={handleTeamNameChange}
          />
          <div className='space-y-2'>
            <div className='font-medium leading-6 text-gray-900'>
              Invite teammates
            </div>
            {teamMembers.map((member, index) => (
              <div className='flex items-center justify-between' key={index}>
                <div className='w-11/12'>
                  <TextInput
                    key={`member-${index}`}
                    id={`team-member-${index}`}
                    type='email'
                    placeholder='name@email.com'
                    value={member}
                    onChange={e => handleMemberChange(index, e.target.value)}
                  />
                </div>
                <XMarkIcon
                  onClick={() => removeMember(index)}
                  className='h-5 w-5 text-gray-400 hover:text-red-400 cursor-pointer'
                />
              </div>
            ))}
          </div>
          <div className='w-1/3'>
            <Button variant='neutral' onClick={addMoreEmailInputs}>
              + Add more
            </Button>
          </div>
        </div>
      </div>
      <Button variant='additive' onClick={handleSubmit} loading={isLoading || setOrgNameIsLoading} disabled={isDisabled()}>
        Next
      </Button>
    </div>
  )

  return (
    <>
      <motion.div className='progress-bar' style={{ scaleX }} />
      <SplitWithImageRight content={content} image={InviteTeamImage} />
    </>
  )
}

export default InviteTeam
