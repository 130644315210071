import { useState, useEffect } from 'react';
import api from '../utils/api';
import Cookies from 'js-cookie';

const useFetch = (url, additionalParams = {}) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchData = async () => {
        try {
            const org = Cookies.get('org');
            const response = await api.get(url, { params: { orgId: org, ...additionalParams } });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Check if the redirect cookie is set
                const isRedirected = Cookies.get("isRedirected");

                // Only proceed if not already redirected
                if (!isRedirected) {
                    Cookies.remove("token");
                    Cookies.remove("org");
                    // Set a cookie to indicate that a redirect has occurred
                    Cookies.set("isRedirected"); 
                    window.location.reload();
                }
            }
            setError(error);
            setLoading(false);
            // throw error;
        }
    };

    useEffect(() => {
        fetchData();
    }, [url, additionalParams]);

    const refresh = () => {
        fetchData();
    };

    return { data, loading, error, refresh };
};

export default useFetch;