import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import '../../../utils/one-off-styles/Timeline.css';
import { DataSet, Timeline } from 'vis-timeline/standalone';
import { Skeleton } from 'antd';

const ProjectTimeline = ({ project }) => {
    const containerRef = useRef();
    const [blur, setBlur] = useState(true);
    useEffect(() => {
        var groups = new DataSet([
            { id: "Not Started", content: "Not Started", value: 1, className: "font-semibold text-sm" },
            { id: "In Progress", content: "In Progress", value: 2, className: "font-semibold text-sm" },
            { id: "Done", content: "Done", value: 3, className: "font-semibold text-sm" },
            { id: "Behind", content: "Behind", value: 4, className: "font-semibold text-sm" },
        ]);

        const CustomItem = ({ content }) => (
            <div>
                <span>
                    {content.initials}
                </span>
                <dd>
                    <h3>{content.name}</h3>
                    <p >{content.timeSpend} scheduled</p>
                </dd>
            </div>
        );

        var items = new DataSet(project.tasks.map(task => {
            return {
                id: task.id,
                group: task.status,
                className: task.status.replace(/\s/g, ''),
                content: ReactDOMServer.renderToString(<CustomItem content={{ initials: task.assignee.name.split(' ').map(n => n[0]).join(''), name: task.name, timeSpend: task.timeNeeded }} />),
                start: new Date(task.start),
                end: new Date(task.deadline),
            }
        }));


        var options = {
            groupOrder: function (a, b) {
                return a.value - b.value;
            },
            editable: false,
            zoomable: false,
            horizontalScroll: true,
            selectable: false,
            orientation: { axis: "top" },
            locale: 'en_US',
            min: new Date(new Date(project.start).getTime() - 2 * 24 * 60 * 60 * 1000),
            max: new Date(new Date(project.deadline).getTime() + 2 * 24 * 60 * 60 * 1000),
        };

        var timeline = new Timeline(containerRef.current, items, groups, options);
        // timeline.setOptions(options);
        // timeline.setGroups(groups);
        // timeline.setItems(items);

        // Cleanup function 
        return () => {
            if (timeline) {
                timeline.destroy();
                timeline = null;
            }
        };
    }, [project]);

    useEffect(() => {
        setBlur(true);
        const timer = setTimeout(() => {
            setBlur(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, [project]);

    return (
        <div className='relative'>
            <Skeleton
                active
                loading={blur}
                className='bg-white absolute z-40 w-full h-[50vh]'
                paragraph={{
                    rows: 7,
                }} />
            <div className='shadow-lg rounded-lg border-2 border-slate-200'>
                <div ref={containerRef} />
            </div>
        </div>
    );
};

export default ProjectTimeline;