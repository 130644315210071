import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CalendarIcon,
  FolderIcon,
  CogIcon,
  XMarkIcon
} from '@heroicons/react/24/outline'
import { motion } from 'framer-motion'
import Logo from '../images/Logo.png'

const navigation = [
  { idx: 0, name: 'Calendar', href: '/', icon: CalendarIcon },
  { idx: 1, name: 'Projects', href: '/projects', icon: FolderIcon },
  { idx: 2, name: 'Settings', href: '/settings', icon: CogIcon }
]

const ThinSidebar = ({ thickSidebar, content, currentIdx }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <div>
        {/* This is the mobile sidebar, at some point it would be good to make it show todos 
        instead of nav, but leaving it as is for right now */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-10 lg:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-900/80' />
            </Transition.Child>

            <div className='fixed inset-0 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                      <button
                        type='button'
                        className='-m-2.5 p-2.5'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10'>
                    <div className='flex h-16 shrink-0 items-center'>
                      <img
                        className='h-8 w-auto'
                        src={Logo}
                        alt='Your Company'
                      />
                    </div>
                    <nav className='flex flex-1 flex-col'>
                      <ul className='-mx-2 flex-1 space-y-1'>
                        {navigation.map(item => (
                          <li key={item.name}>
                            {item.idx !== currentIdx ? (
                              <motion.a
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                href={item.href}
                                className='text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                              >
                                <item.icon
                                  className='h-6 w-6 shrink-0'
                                  aria-hidden='true'
                                />
                                {item.name}
                              </motion.a>
                            ) : (
                              <a
                                href={item.href}
                                className='bg-gray-800 text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                              >
                                <item.icon
                                  className='h-6 w-6 shrink-0'
                                  aria-hidden='true'
                                />
                                {item.name}
                              </a>
                            )}
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className='hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-10 lg:block lg:w-20 lg:overflow-y-auto lg:bg-gray-900 lg:pb-4'>
          <div className='flex h-16 shrink-0 items-center justify-center'>
            <img className='h-8 w-auto' src={Logo} alt='Your Company' />
          </div>
          <nav className='mt-8'>
            <ul className='flex flex-col items-center space-y-1'>
              {navigation.map(item => (
                <li key={item.name}>
                  {currentIdx === item.idx ? (
                    <a
                      href={item.href}
                      className='bg-gray-800 text-white group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold'
                    >
                      <item.icon
                        className='h-6 w-6 shrink-0'
                        aria-hidden='true'
                      />
                      <span className='sr-only'>{item.name}</span>
                    </a>
                  ) : (
                    <motion.a
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      href={item.href}
                      className='text-gray-400 hover:text-white hover:bg-gray-800 group flex gap-x-3 rounded-md p-3 text-sm leading-6 font-semibold'
                    >
                      <item.icon
                        className='h-6 w-6 shrink-0'
                        aria-hidden='true'
                      />
                      <span className='sr-only'>{item.name}</span>
                    </motion.a>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <div className='sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden'>
          <button
            type='button'
            className='-m-2.5 p-2.5 text-gray-400 lg:hidden'
            onClick={() => setSidebarOpen(true)}
          >
            <span className='sr-only'>Open sidebar</span>
            <Bars3Icon className='h-6 w-6' aria-hidden='true' />
          </button>
          <div className='flex-1 text-sm font-semibold leading-6 text-white'>
            Home
          </div>
        </div>

        <main className={thickSidebar ? 'lg:pl-96 lg:pr-2' : 'lg:pl-20' } >
          <div className='relative'>
            {content}
          </div>
        </main>

        {thickSidebar && (
          <aside className='fixed bg-todo-bar inset-y-0 left-20 hidden lg:w-72 overflow-y-auto border-r border-gray-200 xl:block'>
            {/* Secondary column (hidden on smaller screens) */}
            {thickSidebar}
          </aside>
        )}
      </div>
    </>
  )
}

export default ThinSidebar
