import React, { useEffect, useState } from 'react'
import SelectMenu from '../components/Inputs/SelectMenu'
import TextInput from '../components/Inputs/TextInput'
import dayjs from 'dayjs'
import Cookies from 'js-cookie';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Logo from '../images/Logo.png'
import usePost from '../hooks/usePost'
import Button from '../components/Buttons/Button'
import { useNavigate, useLocation } from 'react-router-dom';

dayjs.extend(utc);
dayjs.extend(timezone);

const textingSelectMenuData = [
    {
        id: 1, name: 'Founder'
    },
    {
        id: 2, name: 'Agency Owner'
    },
    {
        id: 3, name: 'Freelancer'
    },
    {
        id: 4, name: 'Student'
    },
    {
        id: 5, name: 'Investor'
    },
    {
        id: 6, name: 'Other'
    },
]

const additionalJobData = [
    {
        id: 1, name: 'Web development'
    },
    {
        id: 2, name: 'Web design'
    },
    {
        id: 3, name: 'PR/Communication'
    },
    {
        id: 4, name: 'Marketing'
    },
    {
        id: 5, name: 'Accounting'
    },
    {
        id: 6, name: 'Other'
    },
]

const TextSetup = ({ name = null }) => {
    const [firstName, setFirstName] = useState(name);
    const [textingData, setTextingData] = useState({
        number: '',
        preferences: '',
        workData: { id: -1, name: '---' },
        additionalJobInfo: { id: -1, name: '---' }
    })
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const [completeOnboarding, onboardingResponse, onboardingLoading, onboardingError] = usePost('/beta/account', null, process.env.REACT_APP_RENDER_BACKEND_URL);
    const navigate = useNavigate();
    const location = useLocation();


    const validatePhoneNumber = (phoneNumber) => {
        const cleaned = phoneNumber.replace(/\D/g, '');
        if (cleaned.length === 10) {
            setPhoneNumberError(false)
        } else if (cleaned.length === 11 && cleaned.startsWith('1')) {
            setPhoneNumberError(false)
        } else {
            setPhoneNumberError(true)
        }

    }

    const isDisabled = () => {
        return textingData.number === '' || textingData.preferences === '' || phoneNumberError
    }

    const handleNext = async () => {
        const body = {
            orgId: Cookies.get('org'),
            number: textingData.number,
            name: firstName,
            tzinfo: dayjs.tz.guess(),
            workingHoursRaw: textingData.preferences,
            job: textingData.workData.name,
            additionalJobDetails: textingData.additionalJobInfo.id === -1 ? null : textingData.additionalJobInfo.name
        };
        await completeOnboarding(body);
        navigate('/text-success')
    }

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        if (token) {
            Cookies.set('token', token);
        }
        const org = params.get('org');
        if (org) {
            Cookies.set('org', org);
        }
        if (params.get('name')) {
            setFirstName(params.get('name'));
        }
    }, [location]);

    useEffect(() => {
        setFirstName(name);
    }, [name]);

    return (
        <>
            {/* Mobile View */}
            <div className="lg:hidden flex flex-col" style={{ height: 'calc(100vh - 70px)' }}>
                <div className='w-full shadow-md '>
                    <div className='flex h-16 justify-between items-center'>
                        <img
                            className='ml-4 h-8 w-auto'
                            src={Logo}
                            alt='Astra Logo'
                            style={{ alignSelf: 'center' }}
                        />
                        <h2 className='text-xl font-bold flex-grow text-center'>Set up Astra</h2>
                        <div style={{ width: '48px' }}></div> {/* Placeholder to center the title */}
                    </div>
                </div>
                <div
                    className='m-3 flex-grow'
                >
                    <TextInput
                        label={'Phone Number'}
                        type='tel'
                        placeholder='(123)-456-7890'
                        value={textingData.number}
                        onChange={(e) => setTextingData({ ...textingData, number: e.target.value })}
                        onBlur={() => validatePhoneNumber(textingData.number)}
                    />
                    {phoneNumberError && <p className='mt-2 text-xs text-red-600'>Please enter a valid phone number.</p>
                    }
                    <div
                        className='mt-2'
                    >
                        <label className="block text-sm font-medium text-gray-700">
                            Work Preferences & Goals
                        </label>
                        <textarea
                            rows={5}
                            name="brainDumpContent"
                            id="brainDumpContent"
                            className="block w-full mt-2 resize-none border border-gray-200 p-2  rounded-lg text-gray-900 placeholder:text-gray-400 focus:border-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="I work from 9-5 on mon-friday. I want to launch my own podcast."
                            value={textingData.preferences}
                            onChange={(e) => setTextingData({ ...textingData, preferences: e.target.value })}
                        />
                    </div>
                    <div className='mt-2 '>
                        <SelectMenu
                            label={'I am a'}
                            data={textingSelectMenuData}
                            selected={textingData.workData}
                            setSelected={(e) => setTextingData({ ...textingData, workData: e })} />
                    </div>
                    {(textingData.workData.id === 2 || textingData.workData.id === 3) &&
                        <div className='mt-3 '>
                            <SelectMenu
                                label={`What kind of ${(textingData.workData.name.split(' '))[0]}?`}
                                data={additionalJobData}
                                selected={textingData.additionalJobInfo}
                                setSelected={(e) => setTextingData({ ...textingData, additionalJobInfo: e })} />
                        </div>
                    }
                </div>
                <div className="mt-auto px-4 flex justify-center w-full absolute bottom-0 left-0 right-0">
                    <Button
                        variant='additive'
                        disabled={isDisabled()}
                        loading={onboardingLoading}
                        onClick={handleNext}
                        className='mb-2 w-full'
                    >
                        Next →
                    </Button>
                </div>
            </div>

            {/* Desktop View */}
            <div className="hidden lg:flex flex-col items-center justify-center h-full">
                <div className='w-full border-b border-gray-300'>
                    <div className='flex h-16 justify-between items-center'>
                        <img
                            className='ml-4 h-8 w-auto'
                            src={Logo}
                            alt='Astra Logo'
                            style={{ alignSelf: 'center' }}
                        />
                        <h2 className='text-xl font-bold flex-grow text-center'>Set up Astra</h2>
                        <div style={{ width: '48px' }}></div> {/* Placeholder to center the title */}
                    </div>
                </div>
                <div className='flex justify-center items-center bg-white border border-gray-200 shadow-lg w-full lg:w-1/3 p-3 rounded-lg mt-8'>
                    <div className='w-full'>
                        <TextInput
                            label={'Phone Number'}
                            type='tel'
                            placeholder='(123)-456-7890'
                            value={textingData.number}
                            onChange={(e) => setTextingData({ ...textingData, number: e.target.value })}
                            onBlur={() => validatePhoneNumber(textingData.number)}
                        />
                        {phoneNumberError && <p className='mt-2 text-xs text-red-600'>Please enter a valid phone number.</p>}
                        <div className='mt-2'>
                            <label className="block text-sm font-medium text-gray-700">
                                Work Preferences & Goals
                            </label>
                            <textarea
                                rows={5}
                                name="brainDumpContent"
                                id="brainDumpContent"
                                className="block w-full mt-2 resize-none border border-gray-200 p-2 rounded-lg text-gray-900 placeholder:text-gray-400 focus:border-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                placeholder="I work from 9-5 on mon-friday. I want to launch my own podcast."
                                value={textingData.preferences}
                                onChange={(e) => setTextingData({ ...textingData, preferences: e.target.value })}
                            />
                        </div>
                        <div className='mt-2'>
                            <SelectMenu
                                label={'I am a'}
                                data={textingSelectMenuData}
                                selected={textingData.workData}
                                setSelected={(e) => setTextingData({ ...textingData, workData: e })} />
                        </div>
                        {(textingData.workData.id === 2 || textingData.workData.id === 3) &&
                            <div className='mt-3'>
                                <SelectMenu
                                    label={`What kind of ${(textingData.workData.name.split(' '))[0]}?`}
                                    data={additionalJobData}
                                    selected={textingData.additionalJobInfo}
                                    setSelected={(e) => setTextingData({ ...textingData, additionalJobInfo: e })} />
                            </div>
                        }
                        <Button
                            variant='additive'
                            disabled={isDisabled()}
                            loading={onboardingLoading}
                            onClick={handleNext}
                            className='mt-3 mb-2 w-full'
                        >
                            Next →
                        </Button>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TextSetup