import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import Button from '../../Buttons/Button'
import CircleButton from '../../Buttons/CircleButton'
import ProjectPlannerTable from '../../Tables/ProjectPlannerTable'

const PlanProject = ({
    project,
    setProject,
    handleSchedule,
    isLoading,
    isDisabled,
    goBack,
    showHeader,
    teamMembers,
    titleSize,
    showDelete = true,
    showDetailsButton = false
}) => {
    const navigate = useNavigate();
    const addTask = () => {
        const newTask = {
            id: uuidv4(),
            name: '',
            assignee: undefined,
            timeNeeded: '',
            start: dayjs(),
            deadline: dayjs().add(1, 'day'),
            priority: 'None',
            status: 'Not Started'
        };
        setProject({ ...project, tasks: [...project.tasks, newTask] }, "add", { _taskId: newTask.id, projectId: project.id });
    }

    const deleteTask = id => {
        const updatedTasks = project.tasks.filter(task => task.id !== id);
        setProject({ ...project, tasks: updatedTasks }, "delete", { taskId: id, projectId: project.id });
    }

    const editTask = (id, updatedTask) => {
        const updatedTasks = project.tasks.map(task => task.id === id ? updatedTask : task);
        setProject({ ...project, tasks: updatedTasks });
    }

    return (
        <div className="flex flex-col bg-white overflow-visible">
            {showHeader && (
                <>
                    <div className="flex justify-between items-center mb-1">
                        <h2 className="text-xl font-bold">Planner</h2>
                        <div className="flex items-center w-1/12">
                            <Button variant="neutral" className={'px-0 text-xs'} onClick={goBack}>Back</Button>
                        </div>
                    </div>
                    <p className='text-sm text-gray-600 mb-3'>We used  AI to split the project into tasks. After you approve them, Apollo will find time for your team to work on each task </p>
                </>
            )}
            {showDetailsButton ? <div className="px-2 flex w-2/5 space-x-2 items-center mb-1">
                <p className={`text-sm font-medium my-auto mr-2 ${titleSize}`}>Task List</p>

                <Button variant="additive" className={'!px-0 !py-1 text-xs !w-28 !font-normal'} onClick={() => addTask()}>+ Add Task</Button>
                <Button variant="neutral" className={'!px-0 !py-1 !font-normal text-xs !w-32'} onClick={() => navigate(`/projects/${project.id}`)}>View Project Details</Button>

            </div> :
                <div className="flex w-1/12 items-center mb-1">
                    <h3 className={`text-lg font-semibold my-auto mr-2 ${titleSize}`}>Tasks</h3>
                    <CircleButton className="my-auto" onClick={() => addTask()}>+</CircleButton>
                </div>

            }
            <ProjectPlannerTable tasks={project.tasks} editTask={editTask} showDelete={showDelete} deleteTask={deleteTask} teamMembers={teamMembers} />
            {showHeader && (
                <div className="flex justify-end mt-3">
                    <div className="flex w-1/5">
                        <Button variant="additive" disabled={isDisabled()} onClick={handleSchedule} loading={isLoading} className="w-1/4">Schedule ✨ </Button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlanProject