import React from 'react'
import { motion } from 'framer-motion'

// A generic click handler to redirect to the provided URL
const handleClick = url => {
  window.location.href = url
}

const IntegrationButton = ({ text, icon, redirectUrl }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.9 }}
      onClick={() => handleClick(redirectUrl)}
      className='inline-flex items-center px-4 py-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
    >
      {typeof icon === 'string' ? (
        <img src={icon} alt='' className='h-6 w-6 mr-2' />
      ) : (
        <icon className='h-6 w-6 mr-2' />
      )}
      {text}
    </motion.button>
  )
}

export default IntegrationButton
