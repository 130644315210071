import React, { useState } from 'react';
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { Dialog } from '@headlessui/react'
import Cookies from 'js-cookie';
import usePost from '../../../hooks/usePost';
import convertDurationToSeconds from '../../../utils/formatters/ConvertDuration';
import Modal from './Modal';
import TextInput from '../../Inputs/TextInput';
import DurationInput from '../../Inputs/DurationInput';
import Button from '../../Buttons/Button';
import '../../../utils/one-off-styles/antd.css'

const CreateTask = ({ isVisible, setIsVisible, refresh, refreshTodos, setShowNotification, setNotificationBody }) => {
    const [taskName, setTaskName] = useState('')
    const [startDate, setStartDate] = useState(dayjs())
    const [dueDate, setDueDate] = useState()
    const [timeNeeded, setTimeNeeded] = useState('')
    const [split, setSplit] = useState(false)
    const [createTask, response, isLoading, error] = usePost('/task/schedule')

    const { RangePicker } = DatePicker;


    const reset = () => {
        setTaskName('')
        setStartDate(dayjs())
        setDueDate()
        setTimeNeeded('')
        setSplit(false)
    }
    const handleSchedule = async () => {
        try {
            const requestBody = {
                task: {
                    "name": taskName,
                    "color": "purple",
                    "priority": 2,
                    "begin": startDate.format('YYYY-MM-DDTHH:mm:ssZ'),
                    "end": dueDate.format('YYYY-MM-DDTHH:mm:ssZ'),
                    "description": "",
                    "timeSpend": convertDurationToSeconds(timeNeeded),
                    "orgId": Cookies.get("org")
                },
                
            }
            // If timeSpend is more than 4 hours, set timeblock min to 1 hr and max to 4 hrs
            if (requestBody.task.timeSpend > 14400) {
                requestBody.task.timeblockMin = 3600
                requestBody.task.timeblockMax = 14400
            }
            const response = await createTask(requestBody)
            setNotificationBody({
                title: "Task created successfully 🎉",
                positive: true,
            })
            setShowNotification(true)
            refresh()
            refreshTodos()
            setIsVisible(false)
            reset()
        } catch (error) {
            console.error(error)
            setNotificationBody({
                title: "Error creating task",
                subtext: "Please try again",
                positive: false,
            })
            setShowNotification(true)
        }
    };

    const isDisabled = () => {
        return !taskName || !startDate || !dueDate || !timeNeeded;
    }

    return (
        <Modal open={isVisible} setOpen={setIsVisible}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-5">
                <div className="flex flex-col bg-white">
                    <h2 className="text-xl font-bold mb-4">New Task</h2>
                    <TextInput
                        id='task-name'
                        type='text'
                        placeholder='Write a task name'
                        value={taskName}
                        label='Task Name'
                        onChange={e => setTaskName(e.target.value)}
                        className='mb-4'
                    />
                    <div>
                        <label htmlFor="duration" className="text-sm font-medium text-gray-900 mb-2">Duration</label>
                        <RangePicker
                            id='duration'
                            use12Hours
                            changeOnBlur
                            minuteStep={5}
                            popupClassName='noFooterTimePick'
                            showTime={{
                                format: 'h:mm a',
                            }}
                            allowClear={false}
                            value={[startDate, dueDate]}
                            format="MM/DD/YYYY h:mm a"
                            onChange={value => {
                                setStartDate(value[0]);
                                setDueDate(value[1]);
                            }}
                        />
                        {startDate && dueDate && startDate.isSame(dueDate, 'minute') && <p className='text-xs font-medium mt-1 ml-1 text-red-500'>Start date must be before due date</p>}
                    </div>
                    <label htmlFor="time-needed" className="text-sm font-medium text-gray-900 mt-4">Time Needed</label>
                    <div id="time-needed" className="flex justify-between mb-4">
                        <DurationInput
                            className='w-72'
                            placeholder='1 hr 30 min'
                            value={timeNeeded}
                            setValue={setTimeNeeded}
                            onChange={e => setTimeNeeded(e)}
                        />
                        {/* <div className='flex items-center'>
                            <input
                                id="split"
                                name={split}
                                type="checkbox"
                                checked={split}
                                onChange={e => setSplit(!split)}
                                className="mt-2 mr-3 flex items-center rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label htmlFor="split" className="mt-2 flex items-center w-full text-sm font-medium text-gray-900">Split</label>
                        </div> */}
                    </div>
                    <div className="flex justify-end mt-auto">
                        <Button className="mr-2" variant="neutral" onClick={() => setIsVisible(false)}>Cancel</Button>
                        <Button variant="additive" disabled={isDisabled()} loading={isLoading} onClick={handleSchedule}>Schedule ✨</Button>
                    </div>
                </div>
            </Dialog.Panel>
        </Modal>
    );
};

export default CreateTask;

