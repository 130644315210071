import React, { useState, useCallback } from 'react'
import { Calendar, dateFnsLocalizer, Views, Navigate } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import enUS from 'date-fns/locale/en-US'
import { format, parse, getDay, startOfWeek } from 'date-fns'
import { TrashIcon } from '@heroicons/react/24/outline'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import Button from '../Buttons/Button'
import '../../utils/one-off-styles/MainCalendar.css'


const colorLookup = {
    "gray": { mainText: "text-event-main-text-gray", subText: "text-event-sub-text-gray" },
    "red": { mainText: "text-event-main-text-red", subText: "text-event-sub-text-red" },
    "blue": { mainText: "text-event-main-text-blue", subText: "text-event-sub-text-blue" },
    "yellow": { mainText: "text-event-main-text-yellow", subText: "text-event-sub-text-yellow" },
    "green": { mainText: "text-event-main-text-green", subText: "text-event-sub-text-green" },
    "purple": { mainText: "text-event-main-text-purple", subText: "text-event-sub-text-purple" },
    "default": { mainText: "text-event-main-text-default", subText: "text-event-sub-text-default" },
}

const CustomToolbar = ({ toolbar, view, setView, update, setShowCommandBar }) => {

    const [isHovered, setIsHovered] = useState(false)

    const goToBack = () => {
        toolbar.onNavigate(Navigate.PREVIOUS)
        update(toolbar.date)
    }

    const goToNext = () => {
        toolbar.onNavigate(Navigate.NEXT)
        update(toolbar.date)
    }

    const goToCurrent = () => {
        toolbar.onNavigate(Navigate.TODAY)
        update(new Date())
    }

    return (
        <div className='flex justify-between items-center px-6 py-2'>
            <h1 className='font-bold text-2xl'>{format(toolbar.date, 'MMM yyyy')}</h1>

            <div
                className='relative flex items-center'
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div className='block w-96 rounded-full border-0 py-1.5 pr-16 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 text-left cursor-pointer' onClick={() => setShowCommandBar(true)}>
                    <p className='ml-3 font-semibold text-gray-500'>Command Bar</p>
                </div>

                <div className='absolute inset-y-0 right-0 flex py-1.5 pr-3'>
                    <kbd className='inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400'>
                        ⌘K
                    </kbd>
                </div>
            </div>

            <div className='flex items-center'>
                <span className='isolate inline-flex rounded-md '>
                    <button
                        type='button'
                        className='relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-800  hover:bg-gray-50 focus:z-10'
                        onClick={goToBack}
                    >
                        <span className='sr-only'>Previous</span>
                        <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
                    </button>
                    <button
                        type='button'
                        className='relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-800  hover:bg-gray-50 focus:z-10'
                        onClick={goToNext}
                    >
                        <span className='sr-only'>Next</span>
                        <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
                    </button>
                </span>
                <Button variant='neutral' onClick={goToCurrent}>Today</Button>
            </div>
        </div>
    )
}

const CustomEventComponent = ({ event, handleDeleteEvent }) => {
    return (
        <div className='event-container'>
            <div className='event-content'>
                <p className={`text-sm font-bold w-9/12 ${colorLookup[event.color === "#a4bdfc" ? "gray" : event.color]?.mainText}`}>{event.title}</p>
                <div className={`text-xs font-medium ${colorLookup[event.color === "#a4bdfc" ? "gray" : event.color]?.subText}`}>
                    {format(event.start, 'h:mm a').toLocaleLowerCase()} - {format(event.end, 'h:mm a').toLocaleLowerCase()}
                </div>
            </div>
            <div
                className='delete-icon'
                onClick={() => handleDeleteEvent(event.eventId)}
            >
                <TrashIcon className={`h-5 w-5 bg-transparent ${colorLookup[event.color === "#a4bdfc" ? "gray" : event.color]?.mainText} hover:text-white`} />
            </div>
        </div>
    )
}

const MainCalendar = ({
    events,
    // toggleAddEvent,
    // moveEvent,
    deleteEvent,
    update,
    setShowCommandBar
}) => {
    const [view, setView] = useState('week')

    const locales = {
        'en-US': enUS
    }

    const localizerMain = dateFnsLocalizer({
        format,
        parse,
        startOfWeek,
        getDay,
        locales
    })

    localizerMain.formats.dayFormat = (date, culture, localizer) =>
        localizer.format(date, 'EEE dd', culture)

    localizerMain.formats.timeGutterFormat = (date, culture, localizer) =>
        localizer.format(date, 'h:mm a', culture).toLowerCase()

    // const onEventResize = ({ event, start, end, allDay }) => {
    //   console.log(start)
    // }
    const handleDeleteEvent = eventToDelete => {
        // Add your logic here to delete the event
        deleteEvent(eventToDelete)
    }

    // const handleSelectSlot = useCallback(({ start, end }) => {
    //     toggleAddEvent(start, end)
    // }, [])

    return (
        <div
            style={{
                position: 'relative',
                height: '100vh',
                overflow: 'auto',
                backgroundColor: '#ffffff'
            }}
            className='mainCalendar'
        >
            <Calendar
                localizer={localizerMain}
                defaultDate={new Date()}
                defaultView={view}
                views={['week', 'day']}
                style={{ height: '95vh' }}
                events={events}
                // onEventDrop={moveEvent}
                // onSelectSlot={handleSelectSlot}
                showAllEvents={true}
                // onEventResize={onEventResize}
                // resizable
                timeslots={1}
                step={30}
                scrollToTime={new Date().setHours(8,30)}
                formats={{
                    dayFormat: (date, culture, localizer) =>
                        localizer.format(date, 'EEE dd', culture),
                    // eventTimeRangeFormat: () => {
                    //     return ''
                    // }
                }}
                components={{
                    event: props => (
                        <CustomEventComponent
                            {...props}
                            handleDeleteEvent={handleDeleteEvent}
                        // className='purple'
                        />
                    ),
                    toolbar: toolbar => (
                        <CustomToolbar
                            toolbar={toolbar}
                            view={view}
                            setView={setView}
                            update={update}
                            setShowCommandBar={setShowCommandBar}
                        />
                    )
                }}
                eventPropGetter={event => ({
                    className: event.color === "#a4bdfc" ? "gray" : event.color
                })}
            />
        </div>
    )
}

export default MainCalendar
