import React from 'react';
import Logo from '../images/Logo.png';

const TextSuccess = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-white">
            <img src={Logo} alt="Logo" className="w-1/6 max-w-xs mb-8" />
            <p className="text-center text-lg font-semibold text-gray-800">
                Success! You'll receive a text shortly.
            </p>
        </div>
    );
};

export default TextSuccess;