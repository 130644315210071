import React from 'react';
import DropdownButton from '../components/Buttons/DropdownButton';
import TodoContainer from '../components/Containers/TodoContainer';
const TodoListSidebar = ({ todos, showAddTodo, navigateToProject, showAddBrainDump, completeTodo }) => {
    const items = [
        { name: "New Project", onClick: navigateToProject },
        { name: "New Brain Dump", onClick: showAddBrainDump }
    ]

    // const completeTodo = (todoId, updatedFields) => {
    //     console.log(todoId, updatedFields)
    //     const updatedTodos = todos.map(todo =>
    //         todo.stableId === todoId ? { ...todo, ...updatedFields } : todo
    //     );
    //     setTodos(updatedTodos);
    // };

    return (
        <div className='h-screen bg-offwhite'>
            <div className='flex justify-between items-center p-4 w-full'>
                <h1 className='text-xl font-bold'>Todos</h1>
                <div className='flex w-2/3 justify-end'>
                    <DropdownButton variant="additive" onClick={() => showAddTodo(true)} items={items}>+ New Task</DropdownButton>
                </div>
            </div>
            <hr className='w-full border-t-1 border-gray-400' />
            <div className='mt-3  space-y-4 overflow-y-auto px-3 mb-3'>
                {todos.map((todoDay, todoIndex) => (
                    <TodoContainer key={todoIndex} todoDay={todoDay} completeTodo={completeTodo}/>
                ))}
                {todos.length === 0 && (
                    <div className='flex items-center justify-center pt-80'>
                        <p className='text-center text-gray-500 text-md'>No todos coming up 🎉</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TodoListSidebar;
