import React, { useEffect, useState } from 'react'
import { Disclosure } from '@headlessui/react'
import { TimePicker } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import Cookies from 'js-cookie';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  PlusIcon,
  MinusIcon,
  DocumentDuplicateIcon
} from '@heroicons/react/24/outline'
import { motion, useSpring } from 'framer-motion'
import { format, startOfWeek, eachDayOfInterval, add } from 'date-fns'
import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../images/Logo.png'
import Button from '../components/Buttons/Button'
import WorkingHoursCalendar from '../components/Calendars/WorkingHoursCalendar'
import usePost from '../hooks/usePost'
import '../utils/one-off-styles/antd.css'
import '../utils/one-off-styles/motion.css'


dayjs.extend(utc);
dayjs.extend(timezone);



const SetWorkingHours = ({ newAccount, existingWorkingHours = null, name = null }) => {
  const [workingHours, setWorkingHours] = useState([
    { day: 'Sun', hours: [] },
    { day: 'Mon', hours: [{ id: uuidv4(), start: '9:00 am', end: '10:00 pm' }] },
    { day: 'Tue', hours: [{ id: uuidv4(), start: '9:00 am', end: '10:00 pm' }] },
    { day: 'Wed', hours: [{ id: uuidv4(), start: '9:00 am', end: '10:00 pm' }] },
    { day: 'Thu', hours: [{ id: uuidv4(), start: '9:00 am', end: '10:00 pm' }] },
    { day: 'Fri', hours: [{ id: uuidv4(), start: '9:00 am', end: '10:00 pm' }] },
    { day: 'Sun', hours: [] }
  ])
  const [firstName, setFirstName] = useState(name);

  const [updateWorkingHours, response, isLoading, error] = usePost('/user/update-working-hours');
  const navigate = useNavigate();

  const formatWorkingHours = (workingHours) => {
    const dayMapping = {
      'sun': 'sunday',
      'mon': 'monday',
      'tue': 'tuesday',
      'wed': 'wednesday',
      'thu': 'thursday',
      'fri': 'friday',
      'sat': 'saturday',
    };

    const formattedWorkingHours = {};
    workingHours.forEach(({ day, hours }) => {
      formattedWorkingHours[dayMapping[day.toLowerCase()]] = hours.map(({ start, end }) => [
        dayjs(start, 'h:mm a').format('HH:mm:ss'),
        dayjs(end, 'h:mm a').format('HH:mm:ss'),
      ]);
    });
    return formattedWorkingHours;
  };

  const handleNextClick = async () => {
    const formattedWorkingHours = formatWorkingHours(workingHours);
    const tzinfo = dayjs.tz.guess();
    const orgId = Cookies.get('org');
    const body = {
      workingHours: {
        ...formattedWorkingHours,
        tzinfo,
      },
      orgId,
    };
    await updateWorkingHours(body);
    navigate(newAccount ? '/new-account/preferences' : '/settings');
  };

  const scaleX = useSpring(0.4, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    if (token) {
      Cookies.set('token', token);
    }
    const org = params.get('org');
    if (org) {
      Cookies.set('org', org);
    }
    if (params.get('name')) {
      setFirstName(params.get('name'));
    }
  }, [location]);

  useEffect(() => {
    setFirstName(name);
  }, [name]);

  useEffect(() => {
    if (!existingWorkingHours) {
      const _startOfWeek = startOfWeek(new Date())
      const daysOfWeek = eachDayOfInterval({
        start: _startOfWeek,
        end: add(_startOfWeek, { days: 6 })
      })
      let initialTimesToShow = daysOfWeek.map(day => ({
        day: format(day, 'EEE'),
        hours:
          day.getDay() === 0 || day.getDay() === 6
            ? []
            : [{ id: uuidv4(), start: '9:00 am', end: '5:00 pm' }]
      }))
      setWorkingHours(initialTimesToShow)
    } else {
      setWorkingHours(existingWorkingHours)
    }
  }, [existingWorkingHours])

  const removeHour = (dayIndex, hourIndex) => () => {
    const newWorkingHours = [...workingHours]
    newWorkingHours[dayIndex].hours.splice(hourIndex, 1)
    setWorkingHours(newWorkingHours)
  }

  const addHour = dayIndex => () => {
    const newWorkingHours = [...workingHours]
    newWorkingHours[dayIndex].hours.push({ id: uuidv4(), start: '9:00 am', end: '5:00 pm' })
    setWorkingHours(newWorkingHours)
  }

  const updateHour = (dayIndex, hourIndex, value) => {
    const newWorkingHours = [...workingHours]
    const newStart = value[0].format('h:mm a')
    const newEnd = value[1].format('h:mm a')
    newWorkingHours[dayIndex].hours[hourIndex] = {
      id: newWorkingHours[dayIndex].hours[hourIndex].id,
      start: newStart,
      end: newEnd
    }
    setWorkingHours(newWorkingHours)
  }

  const copyToAll = dayIndex => () => {
    const newWorkingHours = [...workingHours]
    const hours = newWorkingHours[dayIndex].hours
    newWorkingHours.forEach((day, index) => {
      if (index !== dayIndex && day.hours.length !== 0) {
        day.hours = [...hours]
      }
    })
    setWorkingHours(newWorkingHours)
  }


  

  return (
    <>
      {/* Mobile View */}
      <div className="lg:hidden flex flex-col items-center justify-center" style={{ height: 'calc(100vh - 70px)' }}>
        <p>Please come back from a desktop device.</p>
      </div>

      {/* Desktop View */}
      < div className="hidden lg:block" >
        {newAccount && (<motion.div className='progress-bar' style={{ scaleX }} />)}
        <div>
          <Disclosure as='nav' className='border-b border-gray-200 bg-white z-10'>
            {({ open }) => (
              <>
                <div className='w-full shadow-md'>
                  <div className='flex h-16 justify-center items-center'>
                    <div className='flex items-center '>
                      <img
                        className='ml-4 h-8 w-auto'
                        src={Logo}
                        alt='Apollo Logo'
                      />
                    </div>
                    <div className='flex-grow flex justify-center items-center '>
                      <h2 className='text-xl font-bold'>{`${firstName ? `${firstName}'s` : ''} Working Hours`}</h2>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Disclosure>
          {/* </div> */}

          <div className='fixed w-[25rem] max-h-full bg-offwhite top-[66px] bottom-0'>
            <div className='flex justify-between flex-col h-full p-3'>
              <div>
                <h2 className='text-xl font-bold'>
                  When do you start and stop working?
                </h2>
                <p className='font-light text-sm mt-2 mb-2'>
                  Set your working hours to match when you want to work. Don't
                  worry, you can always change this later.
                </p>
                <div className='mt-1 max-h-[36rem] overflow-y-auto'>
                  {workingHours.map((day, dayIndex) => (
                    <div className='flex  mb-3' key={dayIndex}>
                      <p className='mr-2 w-7 font-medium text-sm pt-1' >
                        {day.day}
                      </p>
                      <div className=''>
                        {day.hours.length > 0 ? (
                          day.hours.map((hour, hourIndex) => (
                            <div
                              key={hour.id}
                              className='flex items-center mb-2'
                            >
                              <TimePicker.RangePicker
                                use12Hours
                                format='h:mm a'
                                minuteStep={5}
                                changeOnBlur
                                popupClassName='noFooterTimePick'
                                value={[
                                  dayjs(hour.start, 'h:mm a'),
                                  dayjs(hour.end, 'h:mm a')
                                ]}
                                onChange={value => {
                                  updateHour(dayIndex, hourIndex, value)
                                }}
                              />
                              <div className='ml-2 flex space-x-2'>
                                <MinusIcon
                                  className='h-5 w-5 text-gray-400 hover:text-red-400 cursor-pointer'
                                  onClick={removeHour(dayIndex, hourIndex)}
                                />
                                {hourIndex === 0 ? (
                                  <>
                                    <PlusIcon
                                      className='h-5 w-5 text-gray-400 hover:text-green-400 cursor-pointer'
                                      onClick={addHour(dayIndex)}
                                    />
                                    <DocumentDuplicateIcon
                                      className='h-5 w-5 text-gray-400 hover:text-indigo-400 cursor-pointer'
                                      onClick={copyToAll(dayIndex)}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <PlusIcon className='hidden h-5 w-5 text-gray-400 hover:text-green-400 cursor-pointer' />
                                    <DocumentDuplicateIcon className='hidden h-5 w-5 text-gray-400 hover:text-indigo-400 cursor-pointer' />
                                  </>
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='flex items-center mb-2'>
                            <div className='bg-gray-200 rounded-md px-1 py-0.5 text-center'>
                              <span className='text-gray-600 font-medium text-sm'>
                                No Availability
                              </span>
                            </div>

                            <div className='ml-2 flex space-x-2'>
                              <PlusIcon
                                className='h-5 w-5 text-gray-400 hover:text-green-400 cursor-pointer'
                                onClick={addHour(dayIndex)}
                              />
                              <MinusIcon className='hidden h-5 w-5 text-gray-400 hover:text-red-400 cursor-pointer' />
                              <DocumentDuplicateIcon className='hidden h-5 w-5 text-gray-400 hover:text-indigo-400 cursor-pointer' />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <Button variant='additive' loading={isLoading} onClick={handleNextClick}>Next</Button>
            </div>
          </div>
          <main className='relative p-5 ml-[25rem]'>
            <WorkingHoursCalendar workingHours={workingHours} setWorkingHours={setWorkingHours} />
          </main>
        </div>
      </div >
    </>
  )
}

export default SetWorkingHours
