const convertNumberToPriority = (number) => {
    try {
        switch(number) {
            case 1:
                return 'None';
            case 2:
                return 'Low';
            case 3:
                return 'Medium';
            case 4:
                return 'High';
            case 5:
                return 'Urgent';
            default:
                return 'Invalid';
        }
    } catch (error) {
        console.error(error)
        return 'Invalid'
    }
}

export default convertNumberToPriority;