import React, { useState } from "react";
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDownIcon, ChevronUpIcon, TrashIcon } from '@heroicons/react/24/outline'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import TextInput from "../Inputs/TextInput";
import DurationInput from "../Inputs/DurationInput";
import PrioritySelector from "../Dropdowns/PrioritySelector";
import ProgressSelector from "../Dropdowns/ProgressSelector";
import PlanProject from "../Modals/Views/PlanProject";
import Pill from "../Badges/Pill";
import Badge from "../Badges/Badge";

const progressToColor = {
    'Done': 'green',
    'In Progress': 'yellow',
    'Behind': 'red',
    'Not Started': 'gray'
}

const priorityToColor = {
    'Low': 'green',
    'Medium': 'yellow',
    'High': 'orange',
    'Urgent': 'red',
    'None': 'gray'
}

const ProjectConfigureContainer = ({
    project,
    setProject,
    teamMembers,
    deleteProject }) => {
    const [expanded, setExpanded] = useState(false)

    return (
        <div
            className="cursor-pointer w-full p-3 rounded-lg border-2 flex flex-row items-center border-gray-100 shadow-lg mt-2 mb-1"
            onClick={() => {!expanded && setExpanded(true)}}
        >
            {!expanded ?
                <>
                    <div className="w-full">
                        <div className="flex items-center w-fit justify-between mb-2">
                            <p className="font-medium text-sm mr-3">{project.name}</p>
                            <div className="space-x-3">
                                <Pill variant={priorityToColor[project.priority]}>{project.priority}</Pill>
                                <Badge variant={progressToColor[project.status]}>{project.status}</Badge>
                            </div>
                        </div>
                        <div className="mt-1">
                            <p className="text-sm text-gray-600">{`Due on ${dayjs(project.deadline).format("MMM D YYYY, h:mm a")} • Time needed ${project.timeNeeded}`}</p>
                        </div>
                    </div>
                    <TrashIcon
                        className='h-5 w-5 mr-3 text-gray-500 cursor-pointer hover:text-red-500'
                        onClick={() => deleteProject(project.id)}
                    />
                    <ChevronDownIcon
                        className='h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-300'
                        onClick={() => setExpanded(true)}
                    />
                </>
                :
                <AnimatePresence>
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        className="w-full flex flex-row items-center"
                    >
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <div className='w-9/12'>
                                    <TextInput
                                        id='project-name'
                                        type='text'
                                        placeholder='Write a project name'
                                        value={project.name}
                                        label='Project Name'
                                        onChange={e => setProject(project.id, { ...project, name: e.target.value })}
                                        className='mb-4'
                                    />
                                </div>
                                <div className='flex flex-col w-1/5'>
                                    <DurationInput
                                        className='w-48 h-8 mb-4'
                                        placeholder='1 hr 30 min'
                                        label='Time Needed'
                                        value={project.timeNeeded}
                                        onChange={e => setProject(project.id, { ...project, timeNeeded: e })}
                                    />
                                </div>
                                <div className='flex flex-col items-center mt-4'>
                                    <ChevronUpIcon
                                        className='h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-300'
                                        onClick={() => setExpanded(false)}
                                    />
                                </div>
                            </div>
                            <div className='flex items-center justify-start space-x-6'>
                                <div className='flex flex-col w-1/5'>
                                    <label htmlFor="start-date" className="text-sm font-medium text-gray-900 mb-2">Start Date</label>
                                    <DatePicker
                                        id='start-date'
                                        use12Hours
                                        changeOnBlur
                                        minuteStep={5}
                                        popupClassName='noFooterTimePick'
                                        showTime={{
                                            format: 'h:mm a',
                                        }}
                                        value={dayjs(project.start).isValid() ? dayjs(project.start) : undefined}
                                        allowClear={false}
                                        format="MM/DD/YYYY h:mm a"
                                        onChange={value => {
                                            setProject(project.id, { ...project, start: value });
                                        }}
                                        className='h-8'
                                    />
                                </div>
                                <div className='flex flex-col w-1/5'>
                                    <label htmlFor="start-date" className="text-sm font-medium text-gray-900 mb-2">Deadline</label>
                                    <DatePicker
                                        id='start-date'
                                        use12Hours
                                        changeOnBlur
                                        minuteStep={5}
                                        popupClassName='noFooterTimePick'
                                        showTime={{
                                            format: 'h:mm a',
                                        }}
                                        value={dayjs(project.deadline).isValid() ? dayjs(project.deadline) : undefined}
                                        allowClear={false}
                                        format="MM/DD/YYYY h:mm a"
                                        onChange={value => {
                                            setProject(project.id, { ...project, deadline: value });
                                        }}
                                        className='h-8'
                                        onKeyDown={(e) => {
                                            console.log(`Key pressed: ${e.key}`);
                                            e.preventDefault();
                                        }}
                                    />
                                </div>

                                <div className='flex flex-col items-left'>
                                    <label htmlFor="priority" className="ml-5 text-sm font-medium text-gray-900">Priority</label>
                                    <PrioritySelector
                                        priority={project.priority}
                                        setPriority={value =>
                                            setProject(project.id, { ...project, priority: value })}
                                    />
                                </div>
                                <div className='flex flex-col w-1/5'>
                                    <label htmlFor="priority" className="ml-5 text-sm font-medium text-gray-900">Status</label>
                                    <ProgressSelector
                                        progress={project.status}
                                        className={"!w-28"}
                                        setProgress={value => setProject(project.id, { ...project, status: value })}
                                    />

                                </div>

                            </div>
                            <div className="w-full h-full mt-3">
                                <PlanProject
                                    project={project}
                                    titleSize={"!text-sm font-medium"}
                                    setProject={(updatedProject) => setProject(project.id, updatedProject)}
                                    teamMembers={teamMembers}
                                />
                            </div>
                        </div>

                    </motion.div>
                </AnimatePresence>
            }

        </div>
    )
}


export default ProjectConfigureContainer